import React, { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import "./styles.css";
import { UnitMap, Unit, Floor } from "./components/UnitMap";
import FloorPicker from "./components/FloorPicker";
import UnitPopOver from "./components/UnitPopOver";
import UnitList from "./components/UnitList";
import { useSelector } from 'react-redux';
import * as unitsSlice from '../../features/units/unitsSlice';
import * as engrainSlice from '../../features/engrain/engrainSlice';
import store from '../../redux/store';

export default function Engrain() {
    const [isLoading, setIsLoading] = useState(false);

    const engrainFloors = useSelector(state => state.engrainFloors.floors.data) || [];
    const engrainUnits = useSelector(state => state.engrainUnits.units.data) || [];
    const mmUnits = useSelector(state => state.units.units) || [];

    const [pos, setPos] = useState({ x: 0, y: 0 });
    const [offset, setOffset] = useState({ x: 0, y: 0 });
    const divRef = useRef(null);
    const selectedFacility = useSelector(state => state.facilities.selectedFacility);
    const occupancyRate = useSelector(state => state.units.occupancyRate);
    const monthlyRevenue = useSelector(state => state.units.monthlyRevenue);
    const monthlyOpportunity = useSelector(state => state.units.monthlyOpportunity);

    const isEngrainUnitsLoaded = useSelector(state => state.engrainUnits.isLoaded);
    const isEngrainFloorsLoaded = useSelector(state => state.engrainFloors.isLoaded);
    const isUnitsLoaded = useSelector(state => state.units.isLoaded);

    const engrainFloorsError = useSelector(state => state.engrainFloors.error);
    const engrainUnitsError = useSelector(state => state.engrainUnits.error);

    useEffect(() => {
        const shouldFetchData = !isEngrainFloorsLoaded || !isEngrainUnitsLoaded || !isUnitsLoaded;

        const fetchData = async () => {
            setIsLoading(true);
            try {
                if (!isEngrainFloorsLoaded) {
                    await store.dispatch(engrainSlice.fetchFloors());
                }
                if (!isEngrainUnitsLoaded) {
                    await store.dispatch(engrainSlice.fetchUnits());
                }
                if (!isUnitsLoaded) {
                    await store.dispatch(unitsSlice.fetchUnits(selectedFacility.id));
                }
            } catch (error) {
                console.error("Error fetching data: ", error);
            } finally {
                setIsLoading(false);
            }
        };

        if (shouldFetchData) {
            fetchData();
        }
    }, [isEngrainFloorsLoaded, isEngrainUnitsLoaded, isUnitsLoaded, selectedFacility.id]);

    const [selectedUnitIds, setSelectedUnitIds] = useState([]);
    const [floorId, setFloorId] = useState(engrainFloors.length > 0 ? engrainFloors[0].id : '');
    const [hoveredUnit, setHoveredUnit] = useState(null);

    useEffect(() => {
        if (divRef.current) {
            const rect = divRef.current.getBoundingClientRect();
            setOffset({ x: rect.left, y: rect.top });
        }
    }, [pos, selectedFacility, engrainFloors]);

    const combinedUnits = engrainUnits.map((engrainUnit) => {
        const matchingMmUnit = mmUnits.find(mmUnit => mmUnit.name === engrainUnit.name);

        if (matchingMmUnit) {
            return {
                ...engrainUnit,
                mm_id: matchingMmUnit.id,
                width: matchingMmUnit.width,
                length: matchingMmUnit.length,
                area: matchingMmUnit.area,
                available: matchingMmUnit.available,
                price: matchingMmUnit.price,
                features: matchingMmUnit.features,
                discounts: matchingMmUnit.discounts,
            };
        }

        return engrainUnit;
    });

    const unitsById = useMemo(() => {
        return new Map(combinedUnits.map(unit => [unit.id, unit]));
    }, [combinedUnits]);

    const isSelectedUnit = useCallback((unit) => {
        return selectedUnitIds.includes(unit.id);
    }, [selectedUnitIds]);

    const getSelectedUnitsList = useCallback(() => {
        return selectedUnitIds.map(unitId => unitsById.get(unitId));
    }, [selectedUnitIds, unitsById]);

    const handleUnitClick = useCallback((unit, mapUnit) => {
        setSelectedUnitIds(prevSelectedUnitIds => {
            return isSelectedUnit(unit)
                ? prevSelectedUnitIds.filter(id => id !== unit.id)
                : [...prevSelectedUnitIds, unit.id];
        });
    }, [isSelectedUnit]);

    const handleUnitMouseOver = useCallback((unit, mapUnit) => {
        setHoveredUnit(unit);
    }, [isSelectedUnit]);

    const handleUnitMouseOut = useCallback(() => {
        setHoveredUnit(null);
    }, [isSelectedUnit]);

    let unitsList = <div></div>;
    if (getSelectedUnitsList().length) {
        unitsList = <UnitList
            units={getSelectedUnitsList()}
            onRemove={unitId => setSelectedUnitIds(prev => prev.filter(id => id !== unitId))}
        />;
    }

    let leftSide = <div>
        <div>Occupancy Rate: {occupancyRate}%</div>
        <div>Monthly Revenue: ${new Intl.NumberFormat().format(monthlyRevenue)}</div>
        <div>Monthly Opportunity: ${new Intl.NumberFormat().format(monthlyOpportunity)}</div>
        {unitsList}
    </div>;

    // Check for errors and display appropriate message
    if (engrainFloorsError) {
        return <div>Error fetching floors: {engrainFloorsError}</div>;
    }
    if (engrainUnitsError) {
        return <div>Error fetching units: {engrainUnitsError}</div>;
    }

    // Conditional rendering based on loading state
    if (isLoading || !isEngrainFloorsLoaded || !isEngrainUnitsLoaded || !isUnitsLoaded) {
        return <div>Loading...</div>;
    }

    return (
        <div ref={divRef} className="EngrainApp">
            <FloorPicker
                floors={engrainFloors}
                floorId={floorId}
                onFloorChange={setFloorId}
            />
            {leftSide}
            <div className="Main">
                <UnitMap id={selectedFacility.engrain.h} apiKey={process.env.REACT_APP_ENGRAIN_KEY} pos={{...pos}}>
                    <Floor id={floorId} />
                    {combinedUnits.map(unit => (
                        <Unit
                            key={unit.id}
                            id={unit.id}
                            color={isSelectedUnit(unit) && unit.available ? "#9ECE67" : (isSelectedUnit(unit) && !unit.available ? "#D64B26" : (!isSelectedUnit(unit) && !unit.available ? "#aaa" : "#fff"))}
                            onClick={(mapUnit) => handleUnitClick(unit, mapUnit)}
                            onMouseOver={(mapUnit) => handleUnitMouseOver(unit, mapUnit)}
                            onMouseOut={(mapUnit) => handleUnitMouseOut(unit, mapUnit)}
                            onMouseMove={(_, x, y) => {
                                setPos({ x: x - offset.x, y: y - offset.y })
                            }}
                        />
                    ))}
                </UnitMap>
            </div>
            {hoveredUnit && <UnitPopOver unit={hoveredUnit} {...pos} />}
        </div>
    );
}
