import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// Define initial state for the status slice
const initialState = {
  loading: false,
  statuses: [],
  error: '',
};

const apiUrl = process.env.REACT_APP_API_URL;

// Async thunk for fetching statuses with facilityId as a parameter
export const fetchStatuses = createAsyncThunk(
    'status/fetchStatuses',
    async (facilityId, thunkAPI) => {
      const mmApiKey = thunkAPI.getState().auth.mmApiKey;
  
      try {
        const response = await fetch(`${apiUrl}/v1/facilities/${facilityId}/statuses`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": 'Bearer ' + mmApiKey
          },
          credentials: "same-origin",
        });
  
        if (!response.ok) {
          throw new Error(`Failed to fetch statuses for facility ID: ${facilityId}`);
        }
  
        const statuses = await response.json();
  
        // Perform any necessary processing on statuses data here
  
        return statuses; // Return fetched statuses
      } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
      }
    }
  );

  // Async thunk for creating a new status
export const createStatus = createAsyncThunk(
  'status/createStatus',
  async ({ facilityId, statusData }, thunkAPI) => {
    const mmApiKey = thunkAPI.getState().auth.mmApiKey;

    try {
      const response = await fetch(`${apiUrl}/v1/facilities/${facilityId}/statuses`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": 'Bearer ' + mmApiKey
        },
        body: JSON.stringify(statusData), // Ensure your status data is in the correct format
      });

      if (!response.ok) {
        throw new Error('Failed to create status');
      }

      return await response.json(); // May include the newly created status object
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Async thunk for updating an existing status
export const updateStatus = createAsyncThunk(
  'status/updateStatus',
  async ({ facilityId, statusId, statusData }, thunkAPI) => {
    const mmApiKey = thunkAPI.getState().auth.mmApiKey;

    try {
      const response = await fetch(`${apiUrl}/v1/facilities/${facilityId}/statuses/${statusId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "Authorization": 'Bearer ' + mmApiKey
        },
        body: JSON.stringify(statusData), // Ensure your status data is in the correct format
      });

      if (!response.ok) {
        throw new Error('Failed to update status');
      }

      return await response.json(); // May include the updated status object
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const deleteStatus = createAsyncThunk(
  'status/deleteStatus',
  async ({ facilityId, statusId }, { getState, rejectWithValue }) => {
    const mmApiKey = getState().auth.mmApiKey;
    try {
      const response = await fetch(`${apiUrl}/v1/facilities/${facilityId}/statuses/${statusId}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          "Authorization": 'Bearer ' + mmApiKey
        },
      });
      if (!response.ok) {
        throw new Error('Failed to delete the status');
      }
      return statusId; // Return the ID of the deleted status to potentially remove it from the state
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);


// Create slice for statuses
const statusSlice = createSlice({
  name: 'status',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchStatuses.pending, (state) => {
      state.loading = true;
    })
    .addCase(fetchStatuses.fulfilled, (state, action) => {
      state.loading = false;
      state.statuses = action.payload;
      state.error = '';
    })
    .addCase(fetchStatuses.rejected, (state, action) => {
      state.loading = false;
      state.statuses = [];
      state.error = action.error.message;
    });
    builder.addCase(createStatus.fulfilled, (state, action) => {
      state.statuses.push(action.payload); // Assuming the payload includes the new status
    })
    .addCase(updateStatus.fulfilled, (state, action) => {
      const index = state.statuses.findIndex(status => status.id === action.payload.id);
      if (index !== -1) {
        state.statuses[index] = action.payload; // Update the status in the array
      }
    })
    .addCase(deleteStatus.fulfilled, (state, action) => {
      state.statuses = state.statuses.filter(status => status.id !== action.payload.statusId);
    });
  },
});

export default statusSlice.reducer;
