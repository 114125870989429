import { createSlice,createAsyncThunk } from '@reduxjs/toolkit'
import { setLoading } from '../auth/loadingSlice';
import { PublicClientApplication } from '@azure/msal-browser';


const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_API_CLIENT_ID,
        authority: process.env.REACT_APP_API_AUTHORITY,
        redirectUri: process.env.REACT_APP_REDIRECT_URI
    }
    // ... other configurations
  };
  
  const msalInstance = new PublicClientApplication(msalConfig);
  await msalInstance.initialize();
  

const userToken = sessionStorage.getItem('idToken')
    ? sessionStorage.getItem('idToken')
    : null

const initialState = {
    loading: false,
    userInfo: {}, // for user object
    userToken: null, // for storing the JWT
    error: null,
    success: false, // for monitoring the registration process.
    tokenExpiration: null,
    mmApiKey: null,
}
const apiUrl = process.env.REACT_APP_API_URL;


export const loginUser = createAsyncThunk('auth/loginUser', async (_, { dispatch }) => {
    await msalInstance.initialize();

    const response = await msalInstance.loginRedirect({ 
        scopes: ["user.read"] // Add other scopes as needed
    }).catch((err) => {
        console.error(err);
        // Dispatch an action for failed login if needed
    });

    if (response && response.account) {
        dispatch(setAuthenticated(true));
        localStorage.setItem('isAuthenticated',true)

        // Extract and dispatch additional user info if needed
    }
});

export const logoutUser = createAsyncThunk('auth/logoutUser', async (_, { dispatch }) => {
    try {
        dispatch(setAuthenticated(false)); // Set isAuthenticated to false immediately
        dispatch(setUserEmail(null));
        dispatch(setUserRoles(null));
        dispatch(setMmApiKey(null));
        dispatch(setTokenExpiration(null));
        localStorage.removeItem('isAuthenticated');
        
        await msalInstance.logoutRedirect({
            postLogoutRedirectUri: "/" // Your desired logout redirect URI
        });
        // Since this redirects the page, the lines after might not be executed
    } catch (error) {
        console.error('Logout failed:', error);
    }
});




const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthenticated: (state, action) => {
            state.isAuthenticated = action.payload;
        },
        setTokenExpiration: (state, action) => {
            state.tokenExpiration = action.payload;
        },
        setMmApiKey: (state, action) => {
            state.mmApiKey = action.payload;
        },
        setUserEmail: (state, action) => {
            state.userEmail = action.payload;
        },
        setUserRoles: (state, action) => {
            state.userRoles = action.payload;
        },
        // Add other reducers as needed
    },
    extraReducers: {
        // login user
        [loginUser.pending]: (state) => {
            console.log("Pending")
            state.loading = true
            state.error = null
        },
        [loginUser.fulfilled]: (state ) => {
            console.log('Fulfilled')
            state.loading = false
            // state.userInfo = payload
            // state.userToken = payload.userToken
        },
        [loginUser.rejected]: (state) => {
            console.log("rejected")
            state.loading = false
            // state.error = payload
        },
    }
})

export const { setAuthenticated ,setTokenExpiration, setMmApiKey, setUserEmail, setUserRoles} = authSlice.actions;
export default authSlice.reducer;
// export { authSlice, loginUser }

