import React, { useState } from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button } from 'antd';

const UnitList = ({ units, onRemove }) => {
  const [isExpanded, setIsExpanded] = useState([]);

  let additionalInfo = (unit) => {
    if (isExpanded.includes(unit.name)) {
      return (
        <div style={{ display: 'flex', paddingLeft: 20, flexDirection: 'column' }}>
          <div>
            {unit.price && unit.price.street_rate ? "$" + unit.price.street_rate : "N/A"}
          </div>
          {/* Other details */}
        </div>
      );
    } else {
      return (<div></div>);
    }
  };

  if (!units || units.length === 0) {
    return (
      <div className="UnitList">
        <div className="empty">No units selected</div>
      </div>
    );
  }

  return (
    <div className="UnitList">
      {units.map(unit => (
        <div key={unit.id}>
          <div className="unit" style={{ backgroundColor: unit.available ? "#9ECE67" : "#D64B26" }} >
            <span>
              {unit.name + " • " + (unit.price && unit.price.street_rate ? "$" + unit.price.street_rate : "N/A") + " • " + unit.length + "' x " + unit.width + "' • " + unit.area + " sq feet"}
            </span>
            <Button type="text" onClick={() => {
              if (isExpanded.includes(unit.name)) {
                setIsExpanded(isExpanded.filter(name => name !== unit.name));
              } else {
                setIsExpanded([...isExpanded, unit.name]);
              }
            }}>
              {isExpanded.includes(unit.name) ? <UpOutlined /> : <DownOutlined />}
            </Button>
          </div>
          {additionalInfo(unit)}
        </div>
      ))}
    </div>
  );
};

export default UnitList;