import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialEngrainUnitsState = {
    loading: false,
    isLoaded: false,
    units: {},
    error: ''
};

const fetchUnits = createAsyncThunk(
    'engrainUnits/fetchUnits', 
    async (_, { getState }) => {
        const engrainId = getState().facilities.selectedFacility.engrain.h;
        const response = await fetch(`https://api.unitmap.com/v1/maps/${engrainId}/units?per-page=1000`, {
            method: "GET",
            headers: {
                "API-Key": process.env.REACT_APP_API_KEY
            },
            credentials: "same-origin",
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to fetch units');
        }
        return await response.json();
    }
);

const engrainUnitsSlice = createSlice({
    name: 'engrainUnits',
    initialState: initialEngrainUnitsState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchUnits.pending, state => {
            state.loading = true;
        })
        .addCase(fetchUnits.fulfilled, (state, action) => {
            state.loading = false;
            state.isLoaded = true;
            state.units = action.payload;
            state.error = '';
        })
        .addCase(fetchUnits.rejected, (state, action) => {
            state.loading = false;
            state.isLoaded = false;
            state.units = {};
            state.error = action.error.message;
        });
    }
});

const initialEngrainFloorsState = {
    loading: false,
    isLoaded: false,
    floors: {},
    error: ''
};


const fetchFloors = createAsyncThunk(
    'engrainFloors/fetchFloors', 
    async (_, { getState }) => {
        const dynamicValue = getState().facilities.selectedFacility.engrain.asset_id;
        const response = await fetch(`https://api.unitmap.com/v1/assets/${dynamicValue}/floors`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "API-Key": process.env.REACT_APP_API_KEY
            },
            credentials: "same-origin",
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || 'Failed to fetch floors');
        }
        return await response.json();
    }
);

const engrainFloorsSlice = createSlice({
    name: 'engrainFloors',
    initialState: initialEngrainFloorsState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchFloors.pending, state => {
            state.loading = true;
        })
        .addCase(fetchFloors.fulfilled, (state, action) => {
            state.loading = false;
            state.isLoaded = true;
            state.floors = action.payload;
            state.error = '';
        })
        .addCase(fetchFloors.rejected, (state, action) => {
            state.loading = false;
            state.isLoaded = false;
            state.floors = {};
            state.error = action.error.message;
        });
    }
});

export { engrainUnitsSlice, fetchUnits, engrainFloorsSlice, fetchFloors };
