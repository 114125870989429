import React, { useState, useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { click } from "@testing-library/user-event/dist/click";

export const MapContext = React.createContext({
  map: null,
  units: null,
  levels: null,
});

const UnitMap = ({ id, apiKey, width, height, children, onMapLoad}) => {
  const elementRef = useRef(null);
  const [mapState, setMapState] = useState({
    map: null,
    units: null,
    levels: null,
  });

  useEffect(() => {
    // Assuming 'unitmap' is a global function from the UnitMap SDK
    // eslint-disable-next-line no-undef
    let map = unitmap(elementRef.current, apiKey);

    map.load(id, () => {
      setMapState({
        map: map,
        units: map.units(),
        levels: map.levels(),
      });
      if (onMapLoad) {
        onMapLoad(map); // Calling the callback function with the map object
      }
    });
  }, [id, apiKey, onMapLoad]); // Include onMapLoad in the dependency array if it's expected to change

  return (
    <div ref={elementRef} style={{ width, height }}>
      <MapContext.Provider value={mapState}>{children}</MapContext.Provider>
    </div>
  );
};

UnitMap.defaultProps = {
  width: "100%",
  height: "100%",
};

UnitMap.propTypes = {
  id: PropTypes.string.isRequired,
  apiKey: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  backgroundImage: PropTypes.string,
  onMapLoad: PropTypes.func,
};

export default UnitMap;
