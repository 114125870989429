import React, { useEffect } from 'react';
import { Layout, Card } from 'antd';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'; // Added useDispatch
import DisplayFacilityDetailsObject from './FacilityDetailsDisplay';

const { Content } = Layout;

const FacilityDetailsPage = () => {
   
  
    const selectedFacility = useSelector((state) => state.facilities.selectedFacility); 
 
  return (
   
    <Layout hasSider>
      <Card
        bordered={true}
        className='layoutStyle'
        title={`Details for ${selectedFacility.name}`}
      >
        <Content>
          {/* You can add more detailed components here */}
          <DisplayFacilityDetailsObject selectedFacility={selectedFacility} />
          {/* Additional sections for more details */}
          <div>
            {/* Your additional details here */}
          </div>
        </Content>
      </Card>
    </Layout>
  );
};

export default FacilityDetailsPage;
