import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setLoading } from "../auth/loadingSlice";
import { getState } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAuthenticated } from "../auth/authSlice";
import rStore from "../../redux/store";
import { logoutUser, setUserRoles } from "../auth/authSlice";
import { useSelector } from "react-redux";


const initialState = {
  loading: false,
  facilities: null,
  biFacilities: null,
  selectedFacility: null,
  error: "",
};
let brands = [];
function mergeAdditionalDataByNsaId(facilities, additionalData) {
  const additionalDataMap = additionalData.reduce((map, item) => {
    map[item.nsa_id] = item;
    return map;
  }, {});

  return facilities.map((facility) => {
    const additionalFields = additionalDataMap[facility.nsa_id] || {};
    return {
      ...facility,
      custom: {
        ...facility.custom,
        officeSpaces: additionalFields.officeSpaces || "",
        billboards: additionalFields.billboards || "",
        cellTowers: additionalFields.cellTowers || "",
        ssmConversionDate: additionalFields.ssmConversionDate || "",
        totalSqFt: additionalFields.totalSqFt || "",
        units: additionalFields.units || "",
      },
    };
  });
}

function filterStoresByEmail(stores, email, roles) {
  if (
    roles &&
    (roles.includes("admin") ||
      roles.includes("super_admin") ||
      roles.includes("director") ||
      roles.includes("call_center_agent"))
  ) {
    return stores;
  } else {
    const emailRegex = /^([a-z]*)(\d+)@nsabrands\.com$/;
    let emailMatch = email && email.match(emailRegex);
    // console.log("ROLEE", roles);
    let filteredStores = [];
    let seenIds = new Set();
    let isAreaOrRegion =
      roles.includes("AreaManager") || roles.includes("RegionalManager");
    let isPro =
      roles.includes("Southern") ||
      roles.includes("StorageSolutions") ||
      roles.includes("MooveIn") ||
      roles.includes("Hide") ||
      roles.includes("RightSpace");
    function processStore(manager, id, brands) {
      if (manager && id && !seenIds.has(id)) {
        seenIds.add(id);
        let filterProperty =
          manager === "AreaManager"
            ? "area"
            : manager === "RegionalManager"
              ? "region"
              : "nsa_id";

        filteredStores = filteredStores.concat(
          stores.filter((store) => store[filterProperty] === id)
        );
      } else {
        //do brand match here
        console.log("IIIII", brands);
        let filterProperty = brands[0];
        filteredStores = filteredStores.concat(
          stores.filter((store) => {
            console.log("FIL", filterProperty);
            console.log("Store", store);

            if (
              store.brand_name
                .toLowerCase()
                .includes(filterProperty.toLowerCase())
            ) {
              return store;
            }
          })
        );
      }
    }
    // Parse roles if they exist and are in JSON format; otherwise, initialize to empty array
    let parsedRoles = [];
    try {
      parsedRoles = roles ? JSON.parse(roles) : [];
    } catch (e) {
      console.error("Failed to parse roles:", e);
    }

    // Process each role if there are roles, otherwise process based on email only
    if (isAreaOrRegion && parsedRoles.length > 0) {
      const roleRegex = /^[\["']?(AreaManager|RegionalManager)(\d{3})[\]"']?$/;
      parsedRoles.forEach((role) => {
        let roleMatch = role.match(roleRegex);
        let id, manager;

        if (roleMatch) {
          manager = roleMatch[1];
          id = parseInt(roleMatch[2], 10);
          processStore(manager, id);
        }
      });
    } else if (isPro) {
      if (parsedRoles.includes("StorageSolutions")) {
        brands.push("Storage Solutions");
      }
      if (parsedRoles.includes("MooveIn")) {
        brands.push("Moove In");
      }
      if (parsedRoles.includes("MooveInOperator")) {
        brands.push("Moove In");
      }
      if (parsedRoles.includes("HideAwayOperator")) {
        brands.push("Hide");
      }
      if (parsedRoles.includes("Southern")) {
        brands.push("Southern");
      }
      if (parsedRoles.includes("RightSpace")) {
        brands.push("RightSpace");
      }
      processStore(null, null, brands);
    } else if (emailMatch) {
      let manager = emailMatch[1] || "PropertyManager";
      rStore.dispatch(setUserRoles(JSON.stringify(["PropertyManager"])));
      let id = parseInt(emailMatch[2], 10);
      processStore(manager, id);
    }

    if (filteredStores.length === 0) {
      console.error("No matching stores or invalid input format");
      return [];
    }

    console.log("FILTERED STORES:", filteredStores.length);
    return filteredStores;
  }
}

const apiUrl = process.env.REACT_APP_API_URL;

// Assuming you have the JSON data in a separate file or directly in the script
const brandData = [
  { brand_id: 7, brand_name: "Istorage" },
  { brand_id: 8, brand_name: "Move It Storage" },
  { brand_id: 9, brand_name: "Securcare" },
  { brand_id: 10, brand_name: "Northwest" },
  { brand_id: 11, brand_name: "Move It" },
  { brand_id: 12, brand_name: "Moove In Self Storage" },
  { brand_id: 13, brand_name: "Moove In Storage" },
  { brand_id: 14, brand_name: "Irem Set Up Company" },
  { brand_id: 15, brand_name: "Sitelink Site" },
  { brand_id: 16, brand_name: "Sitelink" },
  { brand_id: 17, brand_name: "Test Settings Facility" },
  { brand_id: 18, brand_name: "Xpress Storage" },
  { brand_id: 19, brand_name: "Hide" },
  { brand_id: 20, brand_name: "North Manatee Storage" },
  { brand_id: 21, brand_name: "Tatum Ranch Storage Solutions" },
  { brand_id: 22, brand_name: "5th Street Storage Solutions" },
  { brand_id: 23, brand_name: "Country Club Self" },
  { brand_id: 24, brand_name: "North Country Club Storage Solutions" },
  { brand_id: 25, brand_name: "Maricopa Self Storage" },
  { brand_id: 26, brand_name: "Absolute Low Cost Storage Solutions" },
  { brand_id: 27, brand_name: "Cost Less Self Storage" },
  { brand_id: 28, brand_name: "Fletcher Heights Storage Solutions" },
  { brand_id: 29, brand_name: "Palm Valley Storage Solutions" },
  { brand_id: 30, brand_name: "Broadway Storage Solutions" },
  { brand_id: 31, brand_name: "Rio Estrella Storage Solutions" },
  { brand_id: 32, brand_name: "75th Avenue Storage Solutions" },
  { brand_id: 33, brand_name: "Mesa East Storage Solutions" },
  { brand_id: 34, brand_name: "Westview Storage" },
  { brand_id: 35, brand_name: "Germann Road Storage Solutions" },
  { brand_id: 36, brand_name: "Surprise Storage Solutions" },
  { brand_id: 37, brand_name: "Scottsdale Storage Solutions" },
  { brand_id: 38, brand_name: "A & S Storage Solutions" },
  { brand_id: 39, brand_name: "Executive Square Storage Solutions" },
  { brand_id: 40, brand_name: "Litchfield Park Storage Solutions" },
  { brand_id: 41, brand_name: "Storage Depot Storage Solutions" },
  { brand_id: 42, brand_name: "Bar 4 Storage Solutions" },
  { brand_id: 43, brand_name: "91st Avenue Storage Solutions" },
  { brand_id: 44, brand_name: "Sun City Storage Solutions" },
  { brand_id: 45, brand_name: "Bell Road Storage Solutions" },
  { brand_id: 46, brand_name: "East Mcdowell Storage Solutions" },
  { brand_id: 47, brand_name: "Peoria Grand Storage Solutions" },
  { brand_id: 48, brand_name: "M & M Self Storage" },
  { brand_id: 49, brand_name: "Agua Fria Storage Solutions" },
  { brand_id: 50, brand_name: "Adobe Storage Solutions" },
  { brand_id: 51, brand_name: "New River Self Storage" },
  { brand_id: 52, brand_name: "Allen Avenue Self Storage" },
  { brand_id: 53, brand_name: "Rightspace Storage" },
  { brand_id: 54, brand_name: "Storemore Self Storage" },
  { brand_id: 55, brand_name: "Frontage Self Storage" },
  { brand_id: 56, brand_name: "I" },
  { brand_id: 57, brand_name: "Psa Self Storage" },
  { brand_id: 58, brand_name: "Psa Storage" },
  { brand_id: 59, brand_name: "Statewide Mini Storage" },
  { brand_id: 60, brand_name: "Lake Gregory Self Storage" },
  { brand_id: 61, brand_name: "Storemore" },
  { brand_id: 62, brand_name: "Hyperspace Storage" },
  { brand_id: 63, brand_name: "Unbranded" },
  { brand_id: 64, brand_name: "Southern" },
  { brand_id: 65, brand_name: "Guardian" },
  { brand_id: 66, brand_name: "Storage Solutions" },
];

const fetchBiFacilities = createAsyncThunk(
  "facilities/fetchBiFacilities",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const mmApiKey = state.auth.mmApiKey;

    const fetchAttempt = async (retryCount = 0) => {
      try {
        const response = await fetch(`${apiUrl}/v1/bi/facilities`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${mmApiKey}`,
          },
          credentials: "same-origin",
        });

        if (response.status === 401) {
          if (retryCount < 1) {
            await new Promise((resolve) => setTimeout(resolve, 2000)); // 2 second delay
            return fetchAttempt(retryCount + 1);
          } else {
            thunkAPI.dispatch(logoutUser());
            return thunkAPI.rejectWithValue("Unauthorized");
          }
        }

        if (!response.ok) {
          throw new Error("Failed to fetch BI facilities");
        }

        const biFacilities = await response.json();

        // Add the brand_name to each facility in the response
        const biFacilitiesWithBrandName = biFacilities.map((facility) => {
          const brand = brandData.find((b) => b.brand_id === facility.brand_id);
          return {
            ...facility,
            brand_name: brand ? brand.brand_name : "Unknown Brand",
          };
        });
        const sortedFacilities = [...biFacilitiesWithBrandName]
          .filter((facility) => facility.business_status === "live") // Filter only live facilities
          .sort((a, b) => {
            if (a.brandName === b.brandName) {
              return a.id - b.id;
            }
            return a.brandName.localeCompare(b.brandName);
          });

        let additionalData = state.facilities.additionalData;

        if (!additionalData) {
          additionalData = await thunkAPI
            .dispatch(fetchAdditionalData())
            .unwrap();
        }

        const mergedBiFacilities = mergeAdditionalDataByNsaId(
          sortedFacilities,
          additionalData
        );
        return mergedBiFacilities;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
      }
    };

    return fetchAttempt();
  }
);

const fetchFacilities = createAsyncThunk(
  "facilities/fetchFacilities",
  async (_, thunkAPI) => {
    const state = thunkAPI.getState();
    const userEmail = state.auth.userEmail;
    const roles = state.auth.userRoles;
    const mmApiKey = state.auth.mmApiKey;
    const facilities = state.facilities.facilities;

    if (facilities && facilities.length > 0) {
      return facilities;
    }

    const fetchAttempt = async (retryCount = 0) => {
      try {
        const response = await fetch(`${apiUrl}/v1/facilities`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${mmApiKey}`,
          },
          credentials: "same-origin",
        });

        if (response.status === 401) {
          if (retryCount < 1) {
            await new Promise((resolve) => setTimeout(resolve, 2000)); // 2 second delay
            return fetchAttempt(retryCount + 1);
          } else {
            thunkAPI.dispatch(logoutUser());
            return thunkAPI.rejectWithValue("Unauthorized");
          }
        }

        if (!response.ok) {
          throw new Error("Failed to fetch facilities");
        }

        const stores = await response.json();
        const userStores = await filterStoresByEmail(stores, userEmail, roles);

        let additionalData = state.facilities.additionalData;

        if (!additionalData) {
          additionalData = await thunkAPI
            .dispatch(fetchAdditionalData())
            .unwrap();
        }

        const mergedFacilities = mergeAdditionalDataByNsaId(
          userStores,
          additionalData
        );
        return mergedFacilities;
      } catch (error) {
        return thunkAPI.rejectWithValue(error.message);
      }
    };

    return fetchAttempt();
  }
);

const microMind = process.env.REACT_APP_MICRO_API_URL;
const microMindKey = process.env.REACT_APP_MICRO_KEY;

const fetchAdditionalData = createAsyncThunk(
  "facilities/fetchAdditionalData",
  async (_, thunkAPI) => {
    const { rejectWithValue, dispatch, getState } = thunkAPI;
    const state = getState();
    const userEmail = state.auth.userEmail;
    const roles = state.auth.userRoles;
    const mmApiKey = state.auth.mmApiKey;

    try {
      const response = await fetch(`${microMind}/api/facilitiesStats`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": microMindKey,
          Authorization: `Bearer ${mmApiKey}`,
        },
        credentials: "same-origin",
      });

      if (!response.ok) {
        throw new Error("Failed to fetch additional data");
      }

      const data = await response.json();

      // Now use `data` for your checks
      if (data.valid === false) {
        dispatch(setAuthenticated(false));
        localStorage.setItem("isAuthenticated", "false"); // Make sure you're setting a string value
      }
      const userStores = await filterStoresByEmail(data, userEmail, roles);
      return userStores;

      // return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const fetchFacilityDetails = createAsyncThunk(
  "facilities/fetchFacilityDetails",
  async ({ brand, id }, thunkAPI) => {
    const state = thunkAPI.getState(); // Correct usage of getState
    const mmApiKey = state.auth.mmApiKey;

    try {
      const facilityResponse = await fetch(`${apiUrl}/v1/facilities/${id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${mmApiKey}`,
        },
        credentials: "same-origin",
      });

      if (!facilityResponse.ok) {
        const errorData = await facilityResponse.json();
        throw new Error(errorData.message || "Failed to fetch the facility");
      }
      const facilityData = await facilityResponse.json();

      // Additional call for payment methods if the first call is successful
      const paymentMethodsResponse = await fetch(
        `${apiUrl}/v1/facilities/${id}/payment_methods`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${mmApiKey}`,
          },
          credentials: "same-origin",
        }
      );

      if (!paymentMethodsResponse.ok) {
        const errorData = await paymentMethodsResponse.json();
        throw new Error(errorData.message || "Failed to fetch payment methods");
      }
      const paymentMethodsData = await paymentMethodsResponse.json();

      // Combine the facility data with the payment methods data
      return {
        ...facilityData,
        paymentMethods: paymentMethodsData,
      };
    } catch (error) {
      // Here, you can use thunkAPI.rejectWithValue to pass a custom error payload if needed
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const updateFacility = createAsyncThunk(
  "facilities/updateFacility",
  async ({ id, data, userRoles, userEmail }, { getState, rejectWithValue }) => {
    console.log("USER UPDATE ROLES", userRoles);
    console.log("USER EMAIL", userEmail);
    console.log("DDDDDDD", data);
    const state = getState();
    const mmApiKey = state.auth.mmApiKey;

    const facility = data.facility;
    const facility_id = id ? id : state.facilities.selectedFacility.id;

    // Convert custom fields if present
    if (facility.custom) {
      if (facility.custom.areaNumber) {
        facility.area = parseInt(facility.custom.areaNumber, 10);
      }
      if (facility.custom.regionNumber) {
        facility.region = parseInt(facility.custom.regionNumber, 10);
      }
      if (facility.custom.divisionNumber) {
        facility.division = parseInt(facility.custom.divisionNumber, 10);
      }
    }

    try {
      // Fetch previous facility data before update
      const previousFacilityResponse = await fetch(
        `${apiUrl}/v1/facilities/${facility_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${mmApiKey}`,
          },
          credentials: "same-origin",
        }
      );

      if (!previousFacilityResponse.ok) {
        return rejectWithValue("Failed to fetch previous facility details");
      }

      const previousFacilityData = await previousFacilityResponse.json(); // Store previous data

      // Update facility details
      const updateResponse = await fetch(
        `${apiUrl}/v1/facilities/${facility_id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${mmApiKey}`,
          },
          credentials: "same-origin",
          body: JSON.stringify({ facility }),
        }
      );

      if (!updateResponse.ok) {
        console.log("NOGO");
        const errorData = await updateResponse.json();
        return rejectWithValue(
          errorData.message || "Failed to update the facility"
        );
      } else {
        console.log("GO");
      }

      const updatedData = await updateResponse.json();
      console.log("UPDATED DATA", updatedData);

      // Fetch updated facility details
      const updatedFacilityResponse = await fetch(
        `${apiUrl}/v1/facilities/${facility_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${mmApiKey}`,
          },
          credentials: "same-origin",
        }
      );

      if (!updatedFacilityResponse.ok) {
        return rejectWithValue("Failed to fetch updated facility details");
      }

      const updatedFacilityData = await updatedFacilityResponse.json();

      // Fetch payment methods
      const paymentMethodsResponse = await fetch(
        `${apiUrl}/v1/facilities/${facility_id}/payment_methods`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${mmApiKey}`,
          },
          credentials: "same-origin",
        }
      );

      if (!paymentMethodsResponse.ok) {
        return rejectWithValue("Failed to fetch payment methods");
      }

      const paymentMethodsData = await paymentMethodsResponse.json();
      const emailRecipients = [
        facility.custom?.areaManagerEmail,
        facility.custom?.regionalManagerEmail,
        "kperez@nsareit.net",
        // "jswanson@nsareit.net"
        // Add more recipients as needed
      ].filter((email) => email); // Filter out undefined or null values

      const userEmail = state.auth.userEmail;
      const roles = state.auth.userRoles;
      const notificationFacility = state.facilities.selectedFacility;

      const notifyResponse = await fetch(`http://localhost:3001/api/notify`, {
        method: "POST",
        body: JSON.stringify({
          subject: `${notificationFacility.name} Updated`,
          message: `Facility details for ${notificationFacility.name} with MM id ${facility_id} have been updated by ${state.auth.userEmail}. Please review the changes.`,
          recipients: emailRecipients,
          user: state.auth.userEmail, // Send the user's email as well
          possibleChanges: JSON.stringify(data),
          previousData: state.facilities.selectedFacility
        }),
        headers: {
          "Content-Type": "application/json",
          "x-api-key": microMindKey,
          Authorization: `Bearer ${mmApiKey}`,
        },
        credentials: "same-origin",
      });

      if (userEmail === "jswanson@nsareit.net") {
        const notifyComplete = await notifyResponse.text(); // Process the response as JSON
        console.log("NNNNN", notifyComplete);
      }

      // Combine the facility data with the payment methods
      return {
        ...updatedFacilityData,
        paymentMethods: paymentMethodsData,
      };
    } catch (error) {
      console.log("ERRRR", error);
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);


const facilitiesSlice = createSlice({
  name: "facilities",
  initialState: {
    loading: false,
    facilities: null,
    biFacilities: null,
    selectedFacility: null,
    additionalData: null,
    error: "",
  },
  reducers: {
    updateSelectedFacilityDetails: (state, action) => {
      state.selectedFacility = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFacilities.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchFacilities.fulfilled, (state, action) => {
      state.loading = false;
      state.facilities = action.payload;
      state.error = "";
    });
    builder.addCase(fetchFacilities.rejected, (state, action) => {
      state.loading = false;
      state.facilities = null;
      state.error = action.error.message;
    });
    builder.addCase(fetchBiFacilities.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchBiFacilities.fulfilled, (state, action) => {
      state.loading = false;
      state.biFacilities = action.payload;
      state.error = "";
    });
    builder.addCase(fetchBiFacilities.rejected, (state, action) => {
      state.loading = false;
      state.biFacilities = null;
      state.error = action.error.message;
    });
    builder.addCase(fetchAdditionalData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAdditionalData.fulfilled, (state, action) => {
      state.loading = false;
      state.additionalData = action.payload;
      state.error = "";
    });
    builder.addCase(fetchAdditionalData.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(updateFacility.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateFacility.fulfilled, (state, action) => {
      state.loading = false;
      const index = state.facilities.findIndex(
        (facility) => facility.id === action.payload.id
      );
      if (index !== -1) {
        state.facilities[index] = action.payload;
      }
      if (action.payload.custom_fields) {
        state.selectedFacility = {
          ...state.selectedFacility,
          custom_fields: action.payload.custom_fields,
        };
      } else {
        state.selectedFacility = action.payload;
      }
      state.error = "";
    });
    builder.addCase(updateFacility.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(fetchFacilityDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchFacilityDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.selectedFacility = action.payload;
      state.error = "";
    });
    builder.addCase(fetchFacilityDetails.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export const { updateSelectedFacilityDetails } = facilitiesSlice.actions;

export {
  facilitiesSlice,
  fetchBiFacilities,
  fetchFacilities,
  updateFacility,
  fetchFacilityDetails,
  fetchAdditionalData,
};

// export const { updateSelectedFacilityDetails } = facilitiesSlice.actions;

// export {
//   facilitiesSlice,
//   fetchBiFacilities,
//   fetchFacilities,
//   updateFacility,
//   fetchFacilityDetails,
// };
