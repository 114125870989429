import React, { useEffect, useState } from "react";
import {
  Drawer,
  Form,
  Input,
  Button,
  Spin,
  notification,
  Space,
  Row,
  Col,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { updateFacility } from "../features/facilities/facilitiesSlice";
import store from "../redux/store";
const EditCustomFacilityDetails = () => {
  const roles = useSelector((state) => state.auth.userRoles);
  const email = useSelector((state) => state.auth.userEmail);

  const selectedFacility = useSelector(
    (state) => state.facilities.selectedFacility
  );
  const custom_fields = useSelector(
    (state) => state.facilities.selectedFacility.custom
  );

  useEffect(() => {}, [selectedFacility, custom_fields]);

  const { loading } = useSelector((state) => state.facilities);

  const [form] = Form.useForm();
  // const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const openNotification = (type, message) => {
    notification[type]({
      message: type === "success" ? "Success" : "Error",
      description: message,
      placement: "topRight",
    });
  };

  const transformPayload = (values) => {
    return {
      facility: {
        custom: {
          ...values,
        },
      },
    };
  };

  const onFinish = async (values) => {
    // setLoading(true);
    try {
      const transformedValues = transformPayload(values);
      const actionResult = await store.dispatch(
        updateFacility({
          id: selectedFacility.id,
          data: transformedValues,
          userRoles: roles,
          userEmail: email
        })
      );

      if (updateFacility.fulfilled.match(actionResult)) {
        openNotification(
          "success",
          "This facility has been successfully updated!"
        );
        onClose();
      } else {
        openNotification(
          "error",
          "There was an issue updating this facility. Please try again."
        );
      }
    } catch (error) {
      console.error("Error:", error);
      openNotification(
        "error",
        "There was an issue updating this facility. Please try again."
      );
    }
    // finally {
    // setLoading(false);
    // }
  };

  const isSpecialEmail = (email) => {
    const specialEmails = [
      'JEsbenshade@nsareit.net',
      'agoris@nsareit.net',
      'mhall@nsabrands.com',
      'CWess@nsabrands.com',
      'kperez@nsareit.net',
      'Johnnyalambo@gmail.com',
      'jswanson@nsareit.net'
    ];
    return specialEmails.includes(email);
  };

  return (
    <>
       {true && (
        <Button type="primary" onClick={showDrawer} icon={<PlusOutlined />}>
        Edit Custom Details
      </Button>
      )}
      <Drawer
        title="Edit Custom Facility Details"
        width={720}
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
        }}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={() => form.submit()}>
              Submit
            </Button>
          </Space>
        }
      >
        <Spin spinning={loading}>
          <Form
            layout="vertical"
            hideRequiredMark
            onFinish={onFinish}
            form={form}
            initialValues={custom_fields}
          >
            <Row gutter={16}>
              {/* <Col span={12}> */}
              {/* <Form.Item name="msa" label="MSA">
                    <Input placeholder="Enter MSA" />
                </Form.Item> */}
              {/* </Col> */}
              <Col span={12}>
                <Form.Item name="pro" label="Pro">
                  <Input placeholder="Enter Pro" />
                </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item name="fame" label="Fame">
                    <Input placeholder="Enter Fame" />
                </Form.Item>
                </Col> */}
              {/* <Col span={12}> */}
              {/* <Form.Item name="test" label="Test">
                    <Input placeholder="Enter Test" />
                </Form.Item> */}
              {/* </Col> */}
              {/* ... add more Form.Items here, following the same pattern */}
              {/* <Col span={12}>
                <Form.Item name="market" label="Market">
                    <Input placeholder="Enter Market" />
                </Form.Item>
                </Col> */}
              <Col span={12}>
                <Form.Item name="region" label="Region">
                  <Input placeholder="Enter Region" />
                </Form.Item>
              </Col>
              {/* <Col span={12}> */}
              {/* <Form.Item name="history" label="History">
                    <Input placeholder="Enter History" />
                </Form.Item> */}
              {/* </Col> */}
              <Col span={12}>
                <Form.Item name="annexSite" label="Annex Site">
                  <Input placeholder="Enter Annex Site" />
                </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item name="employees" label="Employees">
                    <Input placeholder="Enter Employees" />
                </Form.Item>
                </Col> */}
              <Col span={12}>
                <Form.Item name="landmarks" label="Landmarks">
                  <Input placeholder="Enter Landmarks" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="rmAnalyst" label="RM Analyst">
                  <Input placeholder="Enter RM Analyst" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="areaNumber" label="Area Number">
                  <Input placeholder="Enter Area Number" />
                </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item name="entityCode" label="Entity Code">
                    <Input placeholder="Enter Entity Code" />
                </Form.Item>
                </Col> */}
              <Col span={12}>
                <Form.Item name="gateSystem" label="Gate System">
                  <Input placeholder="Enter Gate System" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="areaManager" label="Area Manager">
                  <Input placeholder="Enter Area Manager" />
                </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item name="attractions" label="Attractions">
                    <Input placeholder="Enter Attractions" />
                </Form.Item>
                </Col> */}
              <Col span={12}>
                <Form.Item name="companyCode" label="Company Code">
                  <Input placeholder="Enter Company Code" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="demographics" label="Demographics">
                  <Input placeholder="Enter Demographics" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="regionNumber" label="Region Number">
                  <Input placeholder="Enter Region Number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="onSiteManager" label="On Site Manager">
                  <Input placeholder="Enter On Site Manager" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="divisionNumber" label="Division Number">
                  <Input placeholder="Enter Division Number" />
                </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item name="localCharities" label="Local Charities">
                    <Input placeholder="Enter Local Charities" />
                </Form.Item>
                </Col> */}
              <Col span={12}>
                <Form.Item name="nsaStoreNumber" label="NSA Store Number">
                  <Input placeholder="Enter NSA Store Number" />
                </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item name="acquisitionDate" label="Acquisition Date">
                    <Input placeholder="Enter Acquisition Date" />
                </Form.Item>
                </Col> */}
              <Col span={12}>
                <Form.Item name="annexSiteNumber" label="Annex Site Number">
                  <Input placeholder="Enter Annex Site Number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="apartmentOnSite" label="Apartment On Site">
                  <Input placeholder="Enter Apartment On Site" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="gateSystemNotes" label="Gate System Notes">
                  <Input placeholder="Enter Gate System Notes" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="regionalManager" label="Regional Manager">
                  <Input placeholder="Enter Regional Manager" />
                </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item name="typicalCustomer" label="Typical Customer">
                    <Input placeholder="Enter Typical Customer" />
                </Form.Item>
                </Col> */}
              <Col span={12}>
                <Form.Item name="areaManagerEmail" label="Area Manager Email">
                  <Input placeholder="Enter Area Manager Email" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="divisionDirector" label="Division Director">
                  <Input placeholder="Enter Division Director" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="divisionGeography" label="Division Geography">
                  <Input placeholder="Enter Division Geography" />
                </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item name="emergencyGateCode" label="Emergency Gate Code">
                    <Input placeholder="Enter Emergency Gate Code" />
                </Form.Item>
                </Col> */}
              <Col span={12}>
                <Form.Item
                  name="upgradesRenovations"
                  label="Upgrades/Renovations"
                >
                  <Input placeholder="Enter Upgrades/Renovations" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="regionalManagerEmail"
                  label="Regional Manager Email"
                >
                  <Input placeholder="Enter Regional Manager Email" />
                </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item name="storeCellPhoneNumber" label="Store Cell Phone Number">
                    <Input placeholder="Enter Store Cell Phone Number" />
                </Form.Item>
                </Col> */}
              <Col span={12}>
                <Form.Item
                  name="areaManagerPhoneNumber"
                  label="Area Manager Phone Number"
                >
                  <Input placeholder="Enter Area Manager Phone Number" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="boatRvSemiTruckWineOther"
                  label="Boat/RV/Semi-Truck/Wine/Other"
                >
                  <Input placeholder="Enter Boat/RV/Semi-Truck/Wine/Other" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="propertyManagementSystem"
                  label="Property Management System"
                >
                  <Input placeholder="Enter Property Management System" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="regionalManagerPhoneNumber"
                  label="Regional Manager Phone Number"
                >
                  <Input placeholder="Enter Regional Manager Phone Number" />
                </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item name="notableNonCompetingBusiness" label="Notable Non-Competing Business">
                    <Input placeholder="Enter Notable Non-Competing Business" />
                </Form.Item>
                </Col> */}
              <Col span={12}>
                <Form.Item
                  name="specialHoursForBusinessTenantsOther"
                  label="Special Hours For Business Tenants/Other"
                >
                  <Input placeholder="Enter Special Hours For Business Tenants/Other" />
                </Form.Item>
              </Col>
              {/* <Col span={12}>
                <Form.Item name="cityAreaNeighborhoodNicknamesAbbreviations" label="City Area/Neighborhood Nicknames/Abbreviations">
                    <Input placeholder="Enter City Area/Neighborhood Nicknames/Abbreviations" />
                </Form.Item>
                </Col> */}
              <Col span={12}>
                <Form.Item
                  name="anythingUniqueAboutThisFacilityOrItsOfferings"
                  label="Anything Unique About This Facility Or Its Offerings"
                >
                  <Input placeholder="Enter Anything Unique About This Facility Or Its Offerings" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};

export default EditCustomFacilityDetails;
