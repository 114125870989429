import React, { useEffect, useState, useRef } from 'react';
import { Table, Button, Input, Spin,Layout,Card ,Space} from 'antd';
import { fetchBiFacilities, updateSelectedFacilityDetails, fetchFacilityDetails } from '../features/facilities/facilitiesSlice';
import { DownloadOutlined } from '@ant-design/icons';

import store from "../redux/store";
import { useNavigate } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
// import Papa from 'papaparse';
import * as XLSX from 'xlsx';

import * as unitsSlice from '../features/units/unitsSlice';
import * as engrainSlice from '../features/engrain/engrainSlice';

const OpsAlignmentFacilitiesTable = ({ loading, biFacilities }) => {
    const [facilityDetailsLoading, setFacilityDetailsLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef();
    const navigate = useNavigate();
    const cookie = document.cookie;

    const handleView = async (record) => {
        setFacilityDetailsLoading(true);

        try {
            const facilityDetailsResponse = await store.dispatch(fetchFacilityDetails({ brand: record.brand, id: record.id, cookie: cookie })).unwrap();
            store.dispatch(updateSelectedFacilityDetails(facilityDetailsResponse));

            await store.dispatch(engrainSlice.fetchUnits());
            await store.dispatch(engrainSlice.fetchFloors());
            await store.dispatch(unitsSlice.fetchUnits(record.id));

            setFacilityDetailsLoading(false);
            navigate(`/facilities/${record.id}`);
        } catch (error) {
            console.error('Error fetching facility details:', error);
            setFacilityDetailsLoading(false);
        }
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const handleSearch = (selectedKeys, confirm) => {
        confirm();
        setSearchText(selectedKeys[0]);
    };

    const getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => handleSearch(selectedKeys, confirm)}
                    icon={<SearchOutlined />}
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Search
                </Button>
                <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => searchInput.current.select());
            }
        },
        render: text =>
            searchText ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchText]}
                    autoEscape
                    textToHighlight={text ? text.toString() : ''}
                />
            ) : (
                text
            ),
    });

    const padNSAId = (id) => {
        if (!id || id.toString().length === 0) {
            return id;
        }
        return id.toString().padStart(4, '0');
    };
    
    const getOriginalId = (originalId, nsaId) => {
        if (originalId == null && nsaId) {
            return padNSAId(nsaId);
        }
        return padNSAId(originalId);
    };
    
    
    const columns = [
        {
            title: 'NSA Store #',
            width: 100,
            dataIndex: 'nsa_id',
            key: 'nsa_id',
            sorter: (a, b) => (a.nsa_id ?? '').toString().localeCompare((b.nsa_id ?? '').toString()),
            ...getColumnSearchProps('nsa_id'),
            render: (text) => padNSAId(text),
        },
        {
            title: 'Original ID',
            width: 150,
            dataIndex: 'original_id',
            key: 'original_id',
            sorter: (a, b) => (a.original_id ? a.original_id.toString() : '').localeCompare((b.original_id ? b.original_id.toString() : '')),
            ...getColumnSearchProps('original_id'),
            render: (text, record) => getOriginalId(text, record.nsa_id),
        },
        {
            title: 'Brand Name',
            width: 100,
            dataIndex: 'brand_name',
            key: 'brand_name',
            sorter: (a, b) => (a.brand_name ?? '').toString().localeCompare((b.brand_name ?? '').toString()),
            ...getColumnSearchProps('brand_name'),
            render: (text) => padNSAId(text),
        },
        
        {
            title: 'Annex Site',
            width: 100,
            dataIndex: ['custom', 'annexSite'],
            key: 'custom.annexSite',
            render: (text, record) => record.custom ? record.custom.annexSite : '',
            sorter: (a, b) => (a.custom?.annexSite ?? '').localeCompare(b.custom?.annexSite ?? ''),
            ...getColumnSearchProps(['custom', 'annexSite']),
        },
        {
            title: 'Annex Site #',
            width: 100,
            dataIndex: ['custom', 'annex_site_number'],
            key: 'custom.annex_site_number',
            render: (text, record) => record.custom ? record.custom.annex_site_number : '',
            sorter: (a, b) => (a.custom?.annex_site_number ?? '').localeCompare(b.custom?.annex_site_number ?? ''),
            ...getColumnSearchProps(['custom', 'annex_site_number']),
        },
        {
            title: 'MSA',
            width: 100,
            dataIndex: ['custom', 'msa'],
            key: 'custom.msa',
            render: (text, record) => record.custom ? record.custom.msa : '',
            sorter: (a, b) => (a.custom?.msa ?? '').localeCompare(b.custom?.msa ?? ''),
            ...getColumnSearchProps(['custom', 'msa']),
        },
        {
            title: 'Site Address',
            width: 100,
            dataIndex: 'address',
            key: 'site_address',
            sorter: (a, b) => (a.address ?? '').localeCompare(b.address ?? ''),
            ...getColumnSearchProps('address'),
        },
        {
            title: 'City',
            width: 100,
            dataIndex: 'city',
            key: 'city',
            sorter: (a, b) => (a.city ?? '').localeCompare(b.city ?? ''),
            ...getColumnSearchProps('city'),
        },
        {
            title: 'State',
            width: 100,
            dataIndex: 'state',
            key: 'state',
            sorter: (a, b) => (a.state ?? '').localeCompare(b.state ?? ''),
            ...getColumnSearchProps('state'),
        },
        {
            title: 'Zip Code',
            width: 100,
            dataIndex: 'zip_code',
            key: 'zip_code',
            sorter: (a, b) => (a.zip_code ?? '').localeCompare(b.zip_code ?? ''),
            ...getColumnSearchProps('zip_code'),
        },
        {
            title: 'Direct Phone #',
            width: 100,
            dataIndex: 'phone_number',
            key: 'phone_number',
            sorter: (a, b) => (a.phone_number ?? '').localeCompare(b.phone_number ?? ''),
            ...getColumnSearchProps('phone_number'),
        },
        {
            title: 'Store Cell Phone #',
            width: 100,
            dataIndex: ['custom', 'store_cell_phone'],
            key: 'custom.store_cell_phone',
            render: (text, record) => record.custom ? record.custom.store_cell_phone : '',
            sorter: (a, b) => (a.custom?.store_cell_phone ?? '').localeCompare(b.custom?.store_cell_phone ?? ''),
            ...getColumnSearchProps(['custom', 'store_cell_phone']),
        },
        {
            title: 'Store Email Address',
            width: 100,
            dataIndex: 'email_address',
            key: 'email_address',
            sorter: (a, b) => (a.email_address ?? '').localeCompare(b.email_address ?? ''),
            ...getColumnSearchProps('email_address'),
        },
        {
            title: 'West or East Division',
            width: 150,
            dataIndex: ['custom', 'divisionGeography'],
            key: 'custom.divisionGeography',
            render: (text, record) => record.custom ? record.custom.divisionGeography : '',
            sorter: (a, b) => (a.custom?.west_east_division ?? '').localeCompare(b.custom?.divisionGeography ?? ''),
            ...getColumnSearchProps(['custom', 'divisionGeography']),
        },
        {
            title: 'Division #',
            width: 150,
            dataIndex: ['custom', 'divisionNumber'],
            key: 'custom.divisionNumber',
            render: (text, record) => record.custom ? record.custom.divisionNumber : '',
            sorter: (a, b) => (a.custom?.divisionNumber ?? '').localeCompare(b.custom?.divisionNumber ?? ''),
            ...getColumnSearchProps(['custom', 'divisionNumber']),
        },
        {
            title: 'Division Director',
            width: 150,
            dataIndex: ['custom', 'division_director'],
            key: 'custom.division_director',
            render: (text, record) => record.custom ? record.custom.division_director : '',
            sorter: (a, b) => (a.custom?.division_director ?? '').localeCompare(b.custom?.division_director ?? ''),
            ...getColumnSearchProps(['custom', 'division_director']),
        },
        {
            title: 'Region #',
            width: 100,
            dataIndex: ['custom', 'regionNumber'],
            key: 'custom.regionNumber',
            render: (text, record) => record.custom ? record.custom.regionNumber : '',
            sorter: (a, b) => (a.custom?.regionNumber ?? '').localeCompare(b.custom?.regionNumber ?? ''),
            ...getColumnSearchProps(['custom', 'regionNumber']),
        },
        {
            title: 'Regional Manager',
            width: 150,
            dataIndex: ['custom', 'regionalManager'],
            key: 'custom.regionalManager',
            render: (text, record) => record.custom ? record.custom.regionalManager : '',
            sorter: (a, b) => (a.custom?.region_manager ?? '').localeCompare(b.custom?.regionalManager ?? ''),
            ...getColumnSearchProps(['custom', 'regionalManager']),
        },
        {
            title: 'Regional Phone #',
            width: 150,
            dataIndex: ['custom', 'regionalManagerPhoneNumber'],
            key: 'custom.regionalManagerPhoneNumber',
            render: (text, record) => record.custom ? record.custom.regionalManagerPhoneNumber : '',
            sorter: (a, b) => (a.custom?.regionalManagerPhoneNumber ?? '').localeCompare(b.custom?.regionalManagerPhoneNumber ?? ''),
            ...getColumnSearchProps(['custom', 'regionalManagerPhoneNumber']),
        },
        {
            title: 'Regional Manager Email Address',
            width: 150,
            dataIndex: ['custom', 'regionalManagerEmail'],
            key: 'custom.regionalManagerEmail',
            render: (text, record) => record.custom ? record.custom.regionalManagerEmail : '',
            sorter: (a, b) => (a.custom?.regionalManagerEmail ?? '').localeCompare(b.custom?.regionalManagerEmail ?? ''),
            ...getColumnSearchProps(['custom', 'regionalManagerEmail']),
        },
        {
            title: 'Area #',
            width: 100,
            dataIndex: ['custom', 'areaNumber'],
            key: 'custom.areaNumber',
            render: (text, record) => record.custom ? record.custom.areaNumber : '',
            sorter: (a, b) => (a.custom?.areaNumber ?? '').localeCompare(b.custom?.areaNumber ?? ''),
            ...getColumnSearchProps(['custom', 'areaNumber']),
        },
        {
            title: 'Area Manager',
            width: 150,
            dataIndex: ['custom', 'areaManager'],
            key: 'custom.areaManager',
            render: (text, record) => record.custom ? record.custom.areaManager : '',
            sorter: (a, b) => (a.custom?.areaManager ?? '').localeCompare(b.custom?.areaManager ?? ''),
            ...getColumnSearchProps(['custom', 'areaManager']),
        },
        {
            title: 'AM Phone #',
            width: 100,
            dataIndex: ['custom', 'areaManagerPhoneNumber'],
            key: 'custom.areaManagerPhoneNumber',
            render: (text, record) => record.custom ? record.custom.areaManagerPhoneNumber : '',
            sorter: (a, b) => (a.custom?.areaManagerPhoneNumber ?? '').localeCompare(b.custom?.areaManagerPhoneNumber ?? ''),
            ...getColumnSearchProps(['custom', 'areaManagerPhoneNumber']),
        },
        {
            title: 'AM Email Address',
            width: 150,
            dataIndex: ['custom', 'areaManagerEmail'],
            key: 'custom.areaManagerEmail',
            render: (text, record) => record.custom ? record.custom.areaManagerEmail : '',
            sorter: (a, b) => (a.custom?.areaManagerEmail ?? '').localeCompare(b.custom?.areaManagerEmail ?? ''),
            ...getColumnSearchProps(['custom', 'areaManagerEmail']),
        },
        {
            title: 'Unit Count',
            width: 100,
            dataIndex: ['custom', 'units'],
            key: 'custom.units',
            render: custom => custom ? custom['units'] : '',
            sorter: (a, b) => (a.custom?.units ?? '').toString().localeCompare((b.custom?.units ?? '').toString()),
            ...getColumnSearchProps(['custom', 'units']),
        },
        {
            title: 'Property Square Footage',
            width: 150,
            dataIndex: ['custom', 'totalSqFt'],
            key: 'custom.totalSqFt',
            render: custom => custom ? custom['totalSqFt'] : '',
            sorter: (a, b) => (a.custom?.totalSqFt ?? '').toString().localeCompare((b.custom?.totalSqFt ?? '').toString()),
            ...getColumnSearchProps(['custom'],['totalSqFt']),
        }, 
        {
            title: 'County',
            width: 150,
            dataIndex: ['custom', 'county'],
            key: 'custom.county',
            render: custom => custom ? custom['county'] : '',
            sorter: (a, b) => (a.custom?.county ?? '').toString().localeCompare((b.custom?.county ?? '').toString()),
            ...getColumnSearchProps(['custom'],['county']),
        },        
        {
            title: 'Commercial Lease Title Holder',
            width: 150,
            dataIndex: ['custom', 'commercial_Lease_Title_Holder'],
            key: 'custom.commercial_Lease_Title_Holder',
            render: custom => custom ? custom['commercial_Lease_Title_Holder'] : '',
            sorter: (a, b) => (a.custom?.commercial_Lease_Title_Holder ?? '').toString().localeCompare((b.custom?.commercial_Lease_Title_Holder ?? '').toString()),
            ...getColumnSearchProps(['custom'],['commercial_Lease_Title_Holder']),
        },       
        {
            title: 'Partnership',
            width: 150,
            dataIndex: ['custom', 'partnership'],
            key: 'custom.partnership',
            render: (text, record) => record.custom ? record.custom.partnership : '',
            sorter: (a, b) => (a.custom?.partnership ?? '').localeCompare(b.custom?.partnership ?? ''),
            ...getColumnSearchProps(['custom', 'partnership']),
        },
        {
            title: 'Company Code',
            width: 150,
            dataIndex: ['custom', 'companyCode'],
            key: 'custom.companyCode',
            render: (text, record) => record.custom ? record.custom.companyCode : '',
            sorter: (a, b) => (a.custom?.companyCode ?? '').localeCompare(b.custom?.companyCode ?? ''),
            ...getColumnSearchProps(['custom', 'companyCode']),
        },
        {
            title: 'Entity Code',
            width: 100,
            dataIndex: ['custom', 'entityCode'],
            key: 'custom.entityCode',
            render: (text, record) => record.custom ? record.custom.entityCode : '',
            sorter: (a, b) => (a.custom?.entityCode ?? '').localeCompare(b.custom?.entityCode ?? ''),
            ...getColumnSearchProps(['custom', 'entityCode']),
        },
        {
            title: 'Acquisition Date',
            width: 150,
            dataIndex: ['custom', 'acquisitionDate'],
            key: 'custom.acquisitionDate',
            render: (text, record) => record.custom ? record.custom.acquisitionDate : '',
            sorter: (a, b) => (a.custom?.acquisitionDate ?? '').localeCompare(b.custom?.acquisitionDate ?? ''),
            ...getColumnSearchProps(['custom', 'acquisitionDate']),
        },
        {
            title: 'Pricing Analyst',
            width: 150,
            dataIndex: ['custom', 'rmAnalyst'],
            key: 'custom.rmAnalyst',
            render: (text, record) => record.custom ? record.custom.rmAnalyst : '',
            sorter: (a, b) => (a.custom?.rmAnalyst ?? '').localeCompare(b.custom?.rmAnalyst ?? ''),
            ...getColumnSearchProps(['custom', 'rmAnalyst']),
        },
        {
            title: 'People Ops Partner',
            width: 150,
            dataIndex: ['custom', 'people_Ops_Partner'],
            key: 'custom.people_Ops_Partner',
            render: custom => custom ? custom['people_Ops_Partner'] : '',
            sorter: (a, b) => (a.custom?.people_Ops_Partner ?? '').toString().localeCompare((b.custom?.people_Ops_Partner ?? '').toString()),
            ...getColumnSearchProps(['custom'],['people_Ops_Partner']),
        },  
        {
            title: 'Recruiting Partner',
            width: 150,
            dataIndex: ['custom', 'recruitingPartner'],
            key: 'custom.recruitingPartner',
            render: custom => custom ? custom['recruitingPartner'] : '',
            sorter: (a, b) => (a.custom?.recruitingPartner ?? '').toString().localeCompare((b.custom?.recruitingPartner ?? '').toString()),
            ...getColumnSearchProps(['custom'],['recruitingPartner']),
        },  
        {
            title: 'Accounting Partner',
            width: 150,
            dataIndex: ['custom', 'accounting_Partner'],
            key: 'custom.accounting_Partner',
            render: custom => custom ? custom['accounting_Partner'] : '',
            sorter: (a, b) => (a.custom?.accounting_Partner ?? '').toString().localeCompare((b.custom?.accounting_Partner ?? '').toString()),
            ...getColumnSearchProps(['custom'],['accounting_Partner']),
        },  
        {
            title: 'Facilities Coordinator',
            width: 150,
            dataIndex: ['custom', 'facilities_coordinator'],
            key: 'custom.facilities_coordinator',
            render: (text, record) => record.custom ? record.custom.facilities_coordinator : '',
            sorter: (a, b) => (a.custom?.facilities_coordinator ?? '').localeCompare(b.custom?.facilities_coordinator ?? ''),
            ...getColumnSearchProps(['custom', 'facilities_coordinator']),
        },
        {
            title: 'Apartment on Site',
            width: 150,
            dataIndex: ['custom', 'apartmentOnSite'],
            key: 'custom.apartmentOnSite',
            render: (text, record) => record.custom ? record.custom.apartmentOnSite : '',
            sorter: (a, b) => (a.custom?.apartmentOnSite ?? '').localeCompare(b.custom?.apartmentOnSite ?? ''),
            ...getColumnSearchProps(['custom', 'apartmentOnSite']),
        },
        {
            title: 'Commercial Office Spaces',
            width: 150,
            dataIndex: ['custom', 'commercial_office_spaces'],
            key: 'custom.commercial_office_spaces',
            render: (text, record) => record.custom ? record.custom.commercial_office_spaces : '',
            sorter: (a, b) => (a.custom?.commercial_office_spaces ?? '').localeCompare(b.custom?.commercial_office_spaces ?? ''),
            ...getColumnSearchProps(['custom', 'commercial_office_spaces']),
        },
        {
            title: 'Billboard',
            width: 150,
            dataIndex: ['custom', 'billboard'],
            key: 'custom.billboard',
            render: (text, record) => record.custom ? record.custom.billboard : '',
            sorter: (a, b) => (a.custom?.billboard ?? '').localeCompare(b.custom?.billboard ?? ''),
            ...getColumnSearchProps(['custom', 'billboard']),
        },
        {
            title: 'Cell Tower',
            width: 150,
            dataIndex: ['custom', 'cell_tower'],
            key: 'custom.cell_tower',
            render: (text, record) => record.custom ? record.custom.cell_tower : '',
            sorter: (a, b) => (a.custom?.cell_tower ?? '').localeCompare(b.custom?.cell_tower ?? ''),
            ...getColumnSearchProps(['custom', 'cell_tower']),
        },
        {
            title: 'SSM Conversion Date',
            width: 150,
            dataIndex: ['custom', 'ssmConversionDate'],
            key: 'custom.ssmConversionDate',
            render: (text, record) => record.custom ? record.custom.ssmConversionDate : '',
            sorter: (a, b) => (a.custom?.ssmConversionDate ?? '').localeCompare(b.custom?.ssmConversionDate ?? ''),
            ...getColumnSearchProps(['custom', 'ssmConversionDate']),
        },
        {
            title: 'Unique Property Details',
            width: 150,
            dataIndex: ['custom', 'anythingUniqueAboutThisFacilityOrItsOfferings'],
            key: 'custom.anythingUniqueAboutThisFacilityOrItsOfferings',
            render: (text, record) => record.custom ? record.custom.anythingUniqueAboutThisFacilityOrItsOfferings : '',
            sorter: (a, b) => (a.custom?.anythingUniqueAboutThisFacilityOrItsOfferings ?? '').localeCompare(b.custom?.anythingUniqueAboutThisFacilityOrItsOfferings ?? ''),
            ...getColumnSearchProps(['custom', 'anythingUniqueAboutThisFacilityOrItsOfferings']),
        }
    ];
    
    const handleExport = () => {
        const excelColumns = columns.map(col => col.title);
      
        const excelData = biFacilities.map(record => {
          const customFields = record.custom || {};
          return {
            'NSA Store #': padNSAId(record.nsa_id || ''),
            'Original ID': getOriginalId(record.original_id, record.nsa_id) || '',
            'Annex Site': customFields.annexSite || '',
            'Annex Site #': customFields.annex_site_number || '',
            'MSA': customFields.msa || '',
            'Site Address': record.site_address || '',
            'City': record.city || '',
            'State': record.state || '',
            'Zip Code': record.zip_code || '',
            'Direct Phone #': record.phone_number || '',
            'Store Cell Phone #': customFields.store_cell_phone || '',
            'Store Email Address': record.email_address || '',
            'West or East Division': customFields.divisionGeography || '',
            'Division #': record.division || '',
            'Division Director': customFields.division_director || '',
            'Region #': record.region || '',
            'Region Manager': customFields.regionalManager || '',
            'Regional Phone #': customFields.regionalManagerPhoneNumber || '',
            'Regional Email Address': customFields.regionalManagerEmail || '',
            'Area #': record.area || '',
            'Area Manager': customFields.areaManager || '',
            'AM Phone #': customFields.areaManagerPhoneNumber || '',
            'AM Email Address': customFields.areaManagerEmail || '',
            'Unit Count': customFields.units || '',
            'Commercial Lease Title Holder': customFields.commercial_Lease_Title_Holder || '',
            'Property Square Footage': customFields.totalSqFt || '',
            'Partnership': customFields.partnership || '',
            'Company Code': customFields.companyCode || '',
            'Entity Code': customFields.entityCode || '',
            'Acquisition Date': customFields.acquisitionDate || '',
            'Pricing Analyst': customFields.pricing_analyst || '',
            'People Ops Partner': customFields.people_Ops_Partner || '',
            'Accounting Partner': customFields.accounting_Partner || '',
            'Facilities Coordinator': customFields.facilities_coordinator || '',
            'Apartment on Site': customFields.apartmentOnSite || '',
            'Commercial Office Spaces': customFields.commercial_office_spaces || '',
            'Billboard': customFields.billboard || '',
            'Cell Tower': customFields.cell_tower || '',
            'SSM Conversion Date': customFields.ssm_conversion_date || '',
            'Unique Property Details': customFields.anythingUniqueAboutThisFacilityOrItsOfferings || '',
            'Lcode': customFields.Lcode || '',
            'Emergency Gate Code': customFields.emergencyGateCode || '',
            'Property Management System': customFields.propertyManagementSystem || '',
            'Complaint Contacts': customFields['pLEASESENDANYCOMPLAINTSTO:'] || ''
          };
        });
      
        const worksheet = XLSX.utils.json_to_sheet(excelData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Facilities");
      
        // Generate buffer
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      
        // Save to file
        const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = URL.createObjectURL(data);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'facilities.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        // <Layout style={{ padding: '24px 24px 24px' }}>
            <Card>
                <Space style={{ marginBottom: 16 }}>
                <Button type="primary" onClick={handleExport} icon={<DownloadOutlined />}>
                    Export Excel
                </Button>
                </Space>
                <Table
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: () => { handleView(record) },
                        };
                    }}
                    dataSource={biFacilities}
                    pagination={{
                        pageSize: 1000,
                      }}
                    columns={columns}
                    loading={{ indicator: <Spin />, spinning: loading ? true : false }}
                    scroll={{
                        x: 'max-content',
                        y: 900,
                      }}
                />
            </Card>
        // </Layout>
    );
};

export default OpsAlignmentFacilitiesTable;


