// import React, { useEffect,useRef } from 'react';
// import { Descriptions,Divider, List, Typography } from 'antd';
// import './facilityDisplayStyles.css'
// import EditCustomFacilityDetails from "./EditCustomFacilityDetails"

import React, { useEffect, useState } from 'react';
import { Descriptions, Divider, Modal } from 'antd';
import './facilityDisplayStyles.css';
import EditCustomFacilityDetails from "./EditCustomFacilityDetails";
import { useSelector } from 'react-redux';


const DisplayFacilityDetailsObject = (props) => {
//   const store = useSelector((reduxStore) => {
//     return reduxStore
//   })
//   const selectedFacility = {...store.facilities.selectedFacility.table}

const custom_fields = useSelector((state) => state.facilities.selectedFacility.custom);

const [isModalVisible, setIsModalVisible] = useState(false);
const [modalContent, setModalContent] = useState('');
const [modalTitle, setModalTitle] = useState('');

const showModal = (title, content) => {
  setModalTitle(title);
  setModalContent(content);
  setIsModalVisible(true);
};

const handleOk = () => {
  setIsModalVisible(false);
};

const handleCancel = () => {
  setIsModalVisible(false);
};

  // const custom_fields = useSelector((state) => state.facilities.selectedFacility.custom);

  useEffect(()=>{
  },[custom_fields])

  return (
    <>

    <Descriptions title="Custom Facility Details" 
      extra={<a href="#"><EditCustomFacilityDetails /></a>} 
      column={3} 
      bordered={false}>

    {custom_fields ? (
        <>

          {/* <Descriptions.Item label="MSA">
            {custom_fields.msa && custom_fields.msa.length > 20 ? (
              <a onClick={() => showModal('MSA', custom_fields.msa)}>{`${custom_fields.msa.substring(0, 20)}...`}</a>
            ) : (
              custom_fields.msa
            )}
          </Descriptions.Item> */}
           {/* <Descriptions.Item label="MSA">{custom_fields.msa}</Descriptions.Item> */}
           <Descriptions.Item label="Pro">{custom_fields.pro}</Descriptions.Item>
           {/* <Descriptions.Item label="Fame">{custom_fields.fame}</Descriptions.Item> */}
           {/* <Descriptions.Item label="Test">{custom_fields.test}</Descriptions.Item> */}
           {/* <Descriptions.Item label="Market">{custom_fields.market}</Descriptions.Item> */}
           <Descriptions.Item label="Region">{custom_fields.region}</Descriptions.Item>
           {/* <Descriptions.Item label="History">{custom_fields.history}</Descriptions.Item> */}
           <Descriptions.Item label="Annex Site">{custom_fields.annexSite}</Descriptions.Item>
           {/* <Descriptions.Item label="Employees">{custom_fields.employees}</Descriptions.Item> */}
           <Descriptions.Item label="Landmarks">{custom_fields.landmarks}</Descriptions.Item>
           <Descriptions.Item label="RM Analyst">{custom_fields.rmAnalyst}</Descriptions.Item>
           <Descriptions.Item label="Area Number">{custom_fields.areaNumber}</Descriptions.Item>
           {/* <Descriptions.Item label="Entity Code">{custom_fields.entityCode}</Descriptions.Item> */}
           <Descriptions.Item label="Gate System">{custom_fields.gateSystem}</Descriptions.Item>
           <Descriptions.Item label="Area Manager">{custom_fields.areaManager}</Descriptions.Item>
           {/* <Descriptions.Item label="Attractions">{custom_fields.attractions}</Descriptions.Item> */}
           <Descriptions.Item label="Company Code">{custom_fields.companyCode}</Descriptions.Item>
           <Descriptions.Item label="Demographics">{custom_fields.demographics}</Descriptions.Item>
           <Descriptions.Item label="Region Number">{custom_fields.regionNumber}</Descriptions.Item>
           <Descriptions.Item label="On Site Manager">{custom_fields.onSiteManager}</Descriptions.Item>
           <Descriptions.Item label="Division Number">{custom_fields.divisionNumber}</Descriptions.Item>
           {/* <Descriptions.Item label="Local Charities">{custom_fields.localCharities}</Descriptions.Item> */}
           <Descriptions.Item label="NSA Store Number">{custom_fields.nsaStoreNumber}</Descriptions.Item>
           {/* <Descriptions.Item label="Acquisition Date">{custom_fields.acquisitionDate}</Descriptions.Item> */}
           <Descriptions.Item label="Annex Site Number">{custom_fields.annexSiteNumber}</Descriptions.Item>
           <Descriptions.Item label="Apartment On Site">{custom_fields.apartmentOnSite}</Descriptions.Item>
           <Descriptions.Item label="Gate System Notes">{custom_fields.gateSystemNotes}</Descriptions.Item>
           <Descriptions.Item label="Regional Manager">{custom_fields.regionalManager}</Descriptions.Item>
           {/* <Descriptions.Item label="Typical Customer">{custom_fields.typicalCustomer}</Descriptions.Item> */}
           <Descriptions.Item label="Area Manager Email">{custom_fields.areaManagerEmail}</Descriptions.Item>
           <Descriptions.Item label="Division Director">{custom_fields.divisionDirector}</Descriptions.Item>
           <Descriptions.Item label="Division Geography">{custom_fields.divisionGeography}</Descriptions.Item>
           {/* <Descriptions.Item label="Emergency Gate Code">{custom_fields.emergencyGateCode}</Descriptions.Item> */}
           <Descriptions.Item label="Upgrades/Renovations">{custom_fields.upgradesRenovations}</Descriptions.Item>
           <Descriptions.Item label="Regional Manager Email">{custom_fields.regionalManagerEmail}</Descriptions.Item>
           {/* <Descriptions.Item label="Store Cell Phone Number">{custom_fields.storeCellPhoneNumber}</Descriptions.Item> */}
           <Descriptions.Item label="Area Manager Phone Number">{custom_fields.areaManagerPhoneNumber}</Descriptions.Item>
           <Descriptions.Item label="Boat/RV/Semi-Truck/Wine/Other">{custom_fields.boatRvSemiTruckWineOther}</Descriptions.Item>
           <Descriptions.Item label="Property Management System">{custom_fields.propertyManagementSystem}</Descriptions.Item>
           <Descriptions.Item label="Regional Manager Phone Number">{custom_fields.regionalManagerPhoneNumber}</Descriptions.Item>
           {/* <Descriptions.Item label="Notable Non-Competing Business">{custom_fields.notableNonCompetingBusiness}</Descriptions.Item> */}
           <Descriptions.Item label="Special Hours For Business Tenants/Other">{custom_fields.specialHoursForBusinessTenantsOther}</Descriptions.Item>
           {/* <Descriptions.Item label="City Area/Neighborhood Nicknames/Abbreviations">{custom_fields.cityAreaNeighborhoodNicknamesAbbreviations}</Descriptions.Item> */}
           <Descriptions.Item label="Anything Unique About This Facility Or Its Offerings">{custom_fields.anythingUniqueAboutThisFacilityOrItsOfferings}</Descriptions.Item>
           </>
        ) : (
          <div>There seems to be a problem loading the facility details, please refresh and try again.</div>
        )}
     
    </Descriptions>
  </>
);
}

export default DisplayFacilityDetailsObject;
