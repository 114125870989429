import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Space, Table, Tag, Button, Input, Spin, Layout, Card } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import * as facilitiesSlice from '../features/facilities/facilitiesSlice'; 
import FacilitiesTable from './FacilitiesTable';

const SearchBar = ({ onSearch }) => {
  return (
    <Input.Search
      placeholder="Search"
      onChange={e => onSearch(e.target.value)}
      enterButton
    />
  );
};

const FacilitiesComponent = () => {
  const dispatch = useDispatch();
  const facilities = useSelector(state => state.facilities.facilities);
  const isLoading = useSelector(state => state.loading.isLoading); // Accessing global loading state
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (facilities?.length === 0) {
      dispatch(facilitiesSlice.fetchFacilities());
    }
  }, [facilities, dispatch]);

  const filteredFacilities = facilities?.filter(record =>
    Object.values(record).some(item =>
      item ? item.toString().toLowerCase().includes(searchText.toLowerCase()) : ''
    )
  );

  return (
    <Layout style={{padding: '24px 24px 24px'}}>
      <Card>

      <SearchBar onSearch={value => setSearchText(value)} />
      <FacilitiesTable loading={isLoading} facilities={filteredFacilities} />
      </Card>
    </Layout>
  );
};

export default FacilitiesComponent;
