import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import { facilitiesSlice } from '../features/facilities/facilitiesSlice';
// import { authSlice } from '../features/auth/authSlice'; // If you need this, uncomment it
import { engrainUnitsSlice } from '../features/engrain/engrainSlice';
import { engrainFloorsSlice } from '../features/engrain/engrainSlice';
import { unitsSlice } from '../features/units/unitsSlice';
import authReducer from '../features/auth/authSlice';
import loadingReducer from '../features/auth/loadingSlice';
import statusReducer from '..//features/facilities/statusSlice'; // Adjust the import path as needed

// Redux Persist configuration
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth'] // only auth will be persisted, add more if needed
};

const rootReducer = combineReducers({
  auth: authReducer,
  facilities: facilitiesSlice.reducer,
  units: unitsSlice.reducer,
  engrainUnits: engrainUnitsSlice.reducer,
  engrainFloors: engrainFloorsSlice.reducer,
  loading: loadingReducer,
  status: statusReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
      serializableCheck: {
          ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE', 'persist/REGISTER'],
      },
  }),
  // ... other store settings
});

export const persistor = persistStore(store);
export default store;
