import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Input, Layout, Card } from 'antd';
import { fetchBiFacilities } from '../features/facilities/facilitiesSlice'; 
import OpsAlignmentFacilitiesTable from './OpsAlignmentTable';

const SearchBar = ({ onSearch }) => {
  return (
    <Input.Search
      placeholder="Search"
      onChange={e => onSearch(e.target.value)}
      enterButton
    />
  );
};

const BiFacilitiesParent = () => {
  const dispatch = useDispatch();
  const biFacilities = useSelector(state => state.facilities.biFacilities);
  const isLoading = useSelector(state => state.facilities.loading);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    dispatch(fetchBiFacilities());
  }, [dispatch]);

  const filteredBiFacilities = biFacilities?.filter(record =>
    Object.values(record).some(item =>
      item ? item.toString().toLowerCase().includes(searchText.toLowerCase()) : ''
    )
  );

  return (
    <Layout style={{ padding: '24px 24px 24px' }}>
      <Card>
        <SearchBar onSearch={value => setSearchText(value)} />
        <Divider>Ops Alignment</Divider>
        <OpsAlignmentFacilitiesTable loading={isLoading} biFacilities={filteredBiFacilities} />
      </Card>
    </Layout>
  );
};

export default BiFacilitiesParent;
