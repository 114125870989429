import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState,
  reducers: {  setLoading: (state, action) => {
    state.isLoading = action.payload;
  },},
  extraReducers: (builder) => {
    // You'll listen for pending, fulfilled, and rejected actions from various thunks here
    builder
      .addMatcher(
        (action) => action.type.endsWith('/pending'),
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type.endsWith('/fulfilled') || action.type.endsWith('/rejected'),
        (state) => {
          state.isLoading = false;
        }
      );
  },
});

export const { setLoading } = loadingSlice.actions;


export default loadingSlice.reducer;
