import React from "react";

const FloorPicker = ({ floors, floorId, onFloorChange }) => (
  <nav className="FloorPicker">
    {floors.map(floor => (
      <button
        key={floor.id}
        className={floor.id === floorId ? "selected" : ""}
        onClick={() => onFloorChange?.(floor.id)}
      >
        {floor.label}
      </button>
    ))}
  </nav>
);

export default FloorPicker;