import React, { useEffect,useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, Navigate } from 'react-router-dom';
import { Layout, Menu, ConfigProvider } from 'antd';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';




import { SignInButton } from './pages/SignInButton';
import Home from './pages/Home';
import FacilityImagesPage from './pages/FacilityImagesPage';
import FacilitiesComponent from './pages/Facilities';
import FacilityTabs from "./pages/FacilityTabs";
import OpsAlignment from '../src/pages/OpsAlignment'
import CreateStatusForm from "./pages/StatusForm";

import Logout from './pages/Logout';
import Navigation from './navigation/Routes';
import Breadcrumbs from './navigation/BreadCrumbs';
import './index.css';

const { Header, Content, Sider } = Layout;
const App = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated && state.auth.userEmail && (state.auth.mmApiKey !== null));
  const [collapsed, setCollapsed] = useState(true);
  const tokenExpiration = useSelector((state) => state.auth.tokenExpiration);
  const navigate = useNavigate();

  const [shouldNavigate, setShouldNavigate] = useState(true);

  useEffect(() => {
    const now = new Date();
    if (shouldNavigate && (!tokenExpiration || new Date(tokenExpiration) < now)) {
      navigate('/login');
      setShouldNavigate(false); // Prevent further navigation
    }
  }, [tokenExpiration, navigate, shouldNavigate]);
  

  return (
      <Layout className="site-layout-content">
        <ConfigProvider
        theme={{
          token: {
            // Seed Token
            colorPrimary: '#0D9CD7',
    
            // Alias Token
            colorBgContainer: '#fff',
          },
        }}>
          <Header className="header">
            {isAuthenticated ? (
              <Navigation />
            ) : (
              <Menu theme="dark" mode="horizontal">
                <Menu.Item key="3">
                  <Link to="/login">Login</Link>
                </Menu.Item>
              </Menu>
            )}
          </Header>

          <Layout style={{padding:'10px,0px,10px,0px'}}>
          {/* <Sider width={200} collapsible collapsed={collapsed} onCollapse={setCollapsed}> */}
              {/* <Menu mode="inline" defaultSelectedKeys={['1']} defaultOpenKeys={['sub2']} style={{ height: '100%', borderRight: 0 }}> */}
                {/* Add your menu items here */}
              {/* </Menu> */}
            {/* </Sider> */}
            <Layout>
              {/* <Breadcrumbs /> */}

              <Content style={{ padding: 0, margin: 0, minHeight: 280 }}>
                <Routes>
                  <Route path="/" element={isAuthenticated ? <Home /> : <SignInButton />} />
                  <Route path="/facilities" element={isAuthenticated ? <FacilitiesComponent /> : <SignInButton />} />
                  <Route path="/facilities/:id" element={isAuthenticated ? <FacilityTabs /> : <SignInButton />} />
                  <Route path="/ops-alignment" element={isAuthenticated ? <OpsAlignment /> : <SignInButton />} />
                  <Route path="/images" element={isAuthenticated ? <FacilityImagesPage /> : <SignInButton />} />

                  {/* <Route path="/facilities/:id/create-status" element={isAuthenticated ? <CreateStatusForm /> : <SignInButton />} /> */}
                  <Route path="/logout" element={isAuthenticated ? <Logout /> : <SignInButton />} />
                  <Route path="/login" element={isAuthenticated ? <Navigate to="/" /> : <SignInButton />} />
                </Routes>
              </Content>
            </Layout>
          </Layout>
        </ConfigProvider>
      </Layout>
  );
};

export default App;
