import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../features/auth/authSlice"; // Adjust the import path
import { Breadcrumb, Layout, Menu, theme } from "antd";
import logo from "../assets/nsaLogoMark.png";

const Navigation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const roles = useSelector((state) => state.auth.userRoles);
  const email = useSelector((state) => state.auth.userEmail);

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate("/"); // Optional: Redirect to home page after logout
  };

  const getSelectedKeys = () => {
    switch (location.pathname) {
      case "/":
        return ["1"];
      case "/facilities":
        return ["2"];
      case "/ops-alignment":
        return ["3"];
      case "/images":
        return ["4"];
      case "/logout":
        return ["5"];
      default:
        return [];
    }
  };

  return (
    <Menu theme="dark" mode="horizontal" selectedKeys={getSelectedKeys()}>
      <Menu.Item key="logo" disabled style={{ pointerEvents: "none" }}>
        <img
          src={logo}
          alt="NSA Logo"
          style={{
            height: "32px",
            marginRight: "16px",
            verticalAlign: "middle",
            filter: "brightness(100%)",
          }}
        />
      </Menu.Item>
      <Menu.Item key="1">
        <Link to="/">Home</Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to="/facilities">Facilities</Link>
      </Menu.Item>
      {(roles?.includes("admin") || roles?.includes("super_admin") || roles.toLowerCase()?.includes("operator")) && (
        <Menu.Item key="3">
          <Link to="/ops-alignment">Ops Alignment</Link>
        </Menu.Item>
      )}
           { (email?.toLowerCase().includes("kcowen") || email?.toLowerCase().includes("pgilmore") || email?.toLowerCase().includes("jcardillo") || email?.includes("johnnyalambo@gmail.com")|| email?.includes("jswanson") || email?.includes("kperez")) && (

        <Menu.Item key="4">
          <Link to="/images">Images</Link>
        </Menu.Item>
      )}
      <Menu.Item key="5" onClick={handleLogout}>
        <Link to="/logout">Logout</Link>
      </Menu.Item>
    </Menu>
  );
};

export default Navigation;
