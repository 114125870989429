import React, { useEffect, useState, useRef } from 'react';
import { Space, Table, Tag, Button, Input, Spin } from 'antd';
import * as facilitiesSlice from '../features/facilities/facilitiesSlice'; 
import { updateSelectedFacilityDetails, fetchFacilityDetails } from '../features/facilities/facilitiesSlice';

import store from "../redux/store"; 
import { useNavigate } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import * as unitsSlice from '../features/units/unitsSlice'; 
import * as engrainSlice from '../features/engrain/engrainSlice';
const FacilitiesTable = ({ loading, facilities }) => {
    const [facilityDetailsLoading, setFacilityDetailsLoading] = useState(false);
  
    const [facilitiesData, setFacilitiesData] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef();
  
    const navigate = useNavigate();
    const cookie = document.cookie;
  
    const handleView = async (record) => {
        setFacilityDetailsLoading(true); 
        
        try {
          const facilityDetailsResponse = await store.dispatch(fetchFacilityDetails({ brand: record.brand, id: record.id, cookie: cookie })).unwrap();
          
          store.dispatch(updateSelectedFacilityDetails(facilityDetailsResponse));
          
          await store.dispatch(engrainSlice.fetchUnits());
          await store.dispatch(engrainSlice.fetchFloors());
          await store.dispatch(unitsSlice.fetchUnits(record.id));
      
          setFacilityDetailsLoading(false); 
          navigate(`/facilities/${record.id}`);
        } catch (error) {
          console.error('Error fetching facility details:', error);
          setFacilityDetailsLoading(false);
          // Handle error, e.g., navigate to error page or show error message
        }
      };
      
  
    
    const handleReset = (clearFilters) => {
      clearFilters();
      setSearchText('');
    };
  
    const handleSearch = (selectedKeys, confirm) => {
      confirm();
      setSearchText(selectedKeys[0]);
    };
    
    const getColumnSearchProps = (dataIndex) => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div style={{ padding: 8 }}>
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => handleSearch(selectedKeys, confirm)}
            style={{ width: 188, marginBottom: 8, display: 'block' }}
          />
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90, marginRight: 8 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
        Object.values(record).some(
          item => item ? item.toString().toLowerCase().includes(value.toLowerCase()) : ''
        ),
      onFilterDropdownVisibleChange: visible => {
        if (visible) {
          setTimeout(() => searchInput.current.select());
        }
      },
      render: text =>
        searchText ? (
          <Highlighter
            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
            searchWords={[searchText]}
            autoEscape
            textToHighlight={text ? text.toString() : ''}
          />
        ) : (
          text
        ),
    });
  
    const columns = [
      {
        title: 'Store #',
        dataIndex: 'nsa_id',
        key: 'nsa_id',
        sorter: (a, b) => a.name.length - b.name.length,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.length - b.name.length,
      },
      {
        title: 'Brand Name',
        dataIndex: 'brand_name',
        key: 'brand',
        sorter: (a, b) => a.name.length - b.name.length,
      },
      {
        title: 'Address',
        dataIndex: 'address',
        key: 'address',
      },
      {
        title: 'City',
        dataIndex: 'city',
        key: 'city',
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        sorter: (a, b) => a.name.length - b.name.length,
      },
      {
        title: 'Zip Code',
        dataIndex: 'zip_code',
        key: 'zip_code',
      },
      {
        title: 'Email',
        dataIndex: 'email_address',
        key: 'email_address',
      },
      // {
      //   title: 'Phone Number',
      //   dataIndex: 'phone_number',
      //   key: 'phone_number',
      // },
      
    ];
  
    return <Table
    onRow={(record, rowIndex) => {
      
      return {
        
        onClick: () => {handleView(record)}, // click row
      };
    }}
    dataSource={facilities} 
    columns={columns}
    loading={{indicator: <Spin/>, spinning: loading?true:false}}
    />;
  };
  

  export default FacilitiesTable;