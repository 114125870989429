import React from 'react';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../features/auth/authSlice'; // Adjust the import path
import { Button } from 'antd';

const Logout = () => {
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(logoutUser());
    };

    return (
        <div>
            <Button onClick={handleLogout}>Log Out</Button>
        </div>
    );
};

export default Logout;
