import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  Spin,
  notification,
} from "antd";
import "./facilityStyles.css";
import { useSelector, useDispatch } from "react-redux";
import { updateFacility } from "../features/facilities/facilitiesSlice";

const { Option } = Select;

const EditDataWarehouseDetails = () => {
  const roles = useSelector((state) => state.auth.userRoles);
  const dispatch = useDispatch();
  const selectedFacility = useSelector(
    (state) => state.facilities.selectedFacility
  );
  const email = useSelector((state) => state.auth.userEmail);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const managementCompanies = {
    0: "NSAStorage",
    1: "Optivest/RightSpace",
    2: "Southern",
    3: "Moove In",
    4: "HideAway",
    5: "StorageSolutions",
    6: "BlueSky",
    7: "Personal Mini",
    8: "Guardian",
  };

  const storageFacilities = {
    7: "Istorage",
    8: "Move It Storage",
    9: "Securcare",
    10: "Northwest",
    13: "Moove In Storage",
    14: "Irem Set Up Company",
    16: "Sitelink",
    17: "Test Settings Facility",
    18: "Xpress Storage",
    19: "Hide Away",
    20: "North Manatee Storage",
    21: "Tatum Ranch Storage Solutions",
    22: "5th Street Storage Solutions",
    23: "Country Club Self",
    24: "North Country Club Storage Solutions",
    25: "Maricopa Self Storage",
    26: "Absolute Low Cost Storage Solutions",
    27: "Cost Less Self Storage",
    28: "Fletcher Heights Storage Solutions",
    29: "Palm Valley Storage Solutions",
    30: "Broadway Storage Solutions",
    31: "Rio Estrella Storage Solutions",
    32: "75th Avenue Storage Solutions",
    33: "Mesa East Storage Solutions",
    34: "Westview Storage",
    35: "Germann Road Storage Solutions",
    36: "Surprise Storage Solutions",
    37: "Scottsdale Storage Solutions",
    38: "A & S Storage Solutions",
    39: "Executive Square Storage Solutions",
    40: "Litchfield Park Storage Solutions",
    41: "Storage Depot Storage Solutions",
    42: "Bar 4 Storage Solutions",
    43: "91st Avenue Storage Solutions",
    44: "Sun City Storage Solutions",
    45: "Bell Road Storage Solutions",
    46: "East Mcdowell Storage Solutions",
    47: "Peoria Grand Storage Solutions",
    48: "M & M Self Storage",
    49: "Agua Fria Storage Solutions",
    50: "Adobe Storage Solutions",
    51: "New River Self Storage",
    52: "Allen Avenue Self Storage",
    53: "Rightspace Storage",
    54: "Storemore Self Storage",
    55: "Frontage Self Storage",
    58: "Psa Storage",
    59: "Statewide Mini Storage",
    60: "Lake Gregory Self Storage",
    61: "Storemore",
    62: "Hyperspace Storage",
    63: "Unbranded",
    64: "Southern",
  };
  // These could be fetched from API or Redux Store
  const numbers = Array.from({ length: 100 }, (_, index) => index + 1);

  // useEffect(() => {
  //   if (selectedFacility) {
  //     form.setFieldsValue(getInitialValues(selectedFacility));
  //   }
  // }, [selectedFacility, form]);

  useEffect(() => {
    if (selectedFacility) {
      form.setFieldsValue(getInitialValues(selectedFacility));
    }
  }, [selectedFacility, form]);

  const showDrawer = () => setOpen(true);
  const onClose = () => setOpen(false);
  const getInitialValues = (facility) => ({
    ...facility,
    regionName: facility.custom?.["location/GEONicknames!"] || "",
  });

  //   const transformPayload = (values) => {
  //     const payload = {
  //         brand_name: values.brand_name, // Assuming this is also updated
  //         phone_number: values.phone_number,
  //         email_address: values.email_address,
  //         latitude: values.latitude,
  //         longitude: values.longitude,
  //         timezone: values.timezone,
  //         custom: { // Adding custom object here
  //             "location/GEONicknames!": values.regionName
  //         }
  //     };

  //     return { facility: payload }; // This is how your backend expects the updated data
  // };

  const onFinish = async (values) => {
    setLoading(true);
    const transformedValues = {
      ...values,
      custom: {
        "location/GEONicknames!": values.regionName,
      },
    };

    try {
      const actionResult = await dispatch(
        updateFacility({
          id: selectedFacility.id,
          data: { facility: transformedValues },
          userRoles: roles,
          userEmail: email
        })
      );

      if (actionResult.meta.requestStatus === "fulfilled") {
        notification.success({
          message: "Success",
          description: "Facility updated successfully!",
        });
        onClose();
      } else {
        throw new Error("Update failed");
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: `Failed to update the facility: ${error.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  const isSpecialEmail = (email) => {
    const specialEmails = [
      "JEsbenshade@nsareit.net",
      "agoris@nsareit.net",
      "mhall@nsabrands.com",
      "CWess@nsabrands.com",
      "kperez@nsareit.net",
      "Johnnyalambo@gmail.com",
      "jswanson@nsareit.net",
    ];
    return specialEmails.includes(email);
  };

  return (
    <>
      {true && (
        <Button type="primary" onClick={showDrawer} icon={<PlusOutlined />}>
          Edit Data Warehouse Details
        </Button>
      )}
      <Drawer
        title="Edit Data Warehouse Details"
        width={720}
        onClose={onClose}
        open={open}
        bodyStyle={{ paddingBottom: 80 }}
        extra={
          <Space>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="primary" onClick={() => form.submit()}>
              Submit
            </Button>
          </Space>
        }
      >
        <Spin spinning={loading}>
          <Form
            layout="vertical"
            form={form}
            hideRequiredMark
            onFinish={onFinish}
          >
            <Row gutter={16}>
              <Col span={12}>
                {/* <Form.Item name="brand_name" label="Brand">
                    <Select placeholder="Select a brand" allowClear>
                    {Object.entries(storageFacilities).map(([id, name]) => (
                        <Option key={id} value={id}>
                        {id}
                        </Option>
                    ))}
                    </Select>
                </Form.Item> */}
                <Form.Item name="brand_id" label="Brand">
                  <Select placeholder="Select a brand" allowClear>
                    {Object.entries(storageFacilities).map(([id, name]) => (
                      <Option key={id} value={JSON.parse(id)}>
                        {name}
                      </Option> // Display name, submit ID
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="reit" label="Is REIT">
                  <Select placeholder="Select true or false" allowClear>
                    <Option value={true}>True</Option>
                    <Option value={false}>False</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item name="division" label="Division">
                  <Select placeholder="Select Division" allowClear>
                    {numbers.map((number) => (
                      <Option key={number} value={number}>
                        {number}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="region" label="Region Number">
                  <Select placeholder="Select Region Number" allowClear>
                    {numbers.map((number) => (
                      <Option key={number} value={number}>
                        {number}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name="area" label="Area">
                  <Select placeholder="Select Area" allowClear>
                    {numbers.map((number) => (
                      <Option key={number} value={number}>
                        {number}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="latitude" label="Latitude">
                  <Input placeholder="Enter latitude" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="longitude" label="Longitude">
                  <Input placeholder="Enter longitude" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="address" label="Address">
                  <Input placeholder="Enter address" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="city" label="City">
                  <Input placeholder="Enter city" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="state" label="State">
                  <Input placeholder="Enter state" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="zip_code" label="Zip Code">
                  <Input placeholder="Enter zip code" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="original_id" label="Original ID">
                  <Input placeholder="Enter original ID" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="mm_id" label="MM ID">
                  <Input placeholder="Enter MM ID" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="pms_store_id" label="PMS Store ID">
                  <Input placeholder="Enter PMS Store ID" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="nsa_id" label="NSA ID">
                  <Input placeholder="Enter NSA ID" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                <Form.Item name="management_company" label="MGMNT Company">
                  <Select placeholder="Select Management" allowClear>
                    {Object.entries(managementCompanies).map(([id, name]) => (
                      <Option key={id} value={id}>
                        {name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name="regionName" label="Region Name">
                  <Input placeholder="Enter Region Name" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Drawer>
    </>
  );
};

export default EditDataWarehouseDetails;

// Northwest - 2076 - McCall ID

// custom: {location/GEONicknames!: "Corporate Store"}
