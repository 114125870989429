import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom';
import { Tabs, Layout, Card } from 'antd';
import store from "../redux/store"


import StatusTable from './StatusTable';
import SettingsForm from './SettingsForm'
import FacilityOverview from './FacilityOverviewPage';
import FacilityDetails from './FacilityDetailsPage'
import Engrain from '../../src/features/engrain/Engrain'
import DataWarehosueDetails from './DataWarehouseDisplay'

const { TabPane } = Tabs;
const { Header, Content, Footer, Sider } = Layout;
const handleEdit = (record) => {
  console.log("Edit:", record);
  // Add your edit functionality here
};

const handleDelete = (record) => {
  console.log("Delete:", record);
  // Add your delete functionality here
};


const FacilityTabs = (facility) => {

  const selectedFacility = useSelector((state) => state.facilities.selectedFacility);
  const roles = useSelector((state) => state.auth.userRoles);

 
  // Function to check if engrain properties are present and not empty
  const hasValidEngrainData = () => {
    return selectedFacility &&
           selectedFacility.engrain &&
           selectedFacility.engrain.h != null &&
           selectedFacility.engrain.d != null &&
           selectedFacility.engrain.asset_id != null;
  };


  

// useEffect(()=>{
//   console.log('selectedFacility',selectedFacility)

//   console.log('hasValidEngrainData',hasValidEngrainData(selectedFacility))

// },[selectedFacility])

  return (
    <Card style={{background: "#F5F5F5"}}>
    <Tabs defaultActiveKey="1" type="card">

      <TabPane tab="Facility Overview" key="1">
        <FacilityOverview></FacilityOverview>
      </TabPane>

      <TabPane tab="Custom Fields" key="2">
        <FacilityDetails></FacilityDetails>
      </TabPane>

      {/* Conditionally render the Engrain tab */}
      {hasValidEngrainData(selectedFacility) && (
        <TabPane tab="Store Map" key="3"> 
          <Engrain/>
        </TabPane>
      )}

      {/* Uncomment and modify further as needed */}
      {/* <TabPane tab="Units" key="4">
        <UnitsTable facilityId={facility.id} />
      </TabPane> */}

       {/* Uncomment and modify further as needed */}
       <TabPane tab="Status Page" key="4">

        <StatusTable facilityId={facility.id} />
      </TabPane>

      {!roles.includes("PropertyManager") && !roles.includes("AreaManager") && (
        <TabPane tab="Settings Page" key="5">

        <SettingsForm facilityId={facility.id} />
        </TabPane>
      )}


      {(JSON.stringify(roles).includes('admin')) && (
       <TabPane tab="Data Warehouse" key="6">
       <DataWarehosueDetails facilityId={facility.id} />

       </TabPane>
      )}

       {/* Uncomment and modify further as needed */}
     

      
      {/* Add more TabPane components as needed */}
    </Tabs>
    </Card>
  );
};


export default FacilityTabs;







