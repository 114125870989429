import React from "react";

const UnitPopOver = ({ unit, x, y }) => {
  // Conditional rendering for when 'unit' or 'unit.price' is not available
  if (!unit || !unit.price) {
    return null; // Or render some fallback UI
  }
  
  return (
    <div
      className="UnitPopOver"
      style={{ transform: `translate(${x}px, ${y}px)`, display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center' }}
    >
      <div>{unit.name}</div>
      <div className="title">{"$" + unit.price.street_rate}</div>
      <div className="title">{unit.available ? "Vacant" : "Occupied"}</div>
    </div>
  )
};

export default UnitPopOver;