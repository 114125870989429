import React, { useEffect, useState } from "react";
import { Descriptions, Divider, Modal, Card } from "antd";
import "./facilityDisplayStyles.css";
import EditDataWarehouseDetails from "./EditDataWarehouseDetails";
import { useSelector } from "react-redux";

const DisplayDataWarehouseDetailsObject = (props) => {
  //   const store = useSelector((reduxStore) => {
  //     return reduxStore
  //   })
  //   const selectedFacility = {...store.facilities.selectedFacility.table}
  const selectedFacility = useSelector(
    (state) => state.facilities.selectedFacility
  );

  const custom_fields = useSelector(
    (state) => state.facilities.selectedFacility.custom
  );
  const roles = useSelector((state) => state.auth.userRoles);
  const email = useSelector((state) => state.auth.userEmail);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  const showModal = (title, content) => {
    setModalTitle(title);
    setModalContent(content);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const {
    access_hours,
    address,
    brand_name,
    city,
    email_address,
    id,
    latitude,
    longitude,
    map,
    name,
    notes,
    office_hours,
    phone_number,
    state,
    timezone,
    website,
    zip_code,
    original_id,
    pms_id,
    reit,
    area,
    region,
    division,
    management_company,
  } = selectedFacility;
  const emergency_code = selectedFacility?.custom?.emergencyGateCode;
  const regionName = selectedFacility.custom
    ? selectedFacility.custom["location/GEONicknames!"]
    : undefined;

  const nsa_id = selectedFacility.custom.nsa_id;

  // const custom_fields = useSelector((state) => state.facilities.selectedFacility.custom);

  useEffect(() => {}, [custom_fields]);

  return (
    <>
      <Card title={selectedFacility ? selectedFacility.name : "Loading..."}>
        <Descriptions
          title="Data Warehouse Details"
          extra={
            <a href="#">
              <EditDataWarehouseDetails />
            </a>
          }
          column={3}
          bordered={false}
        >
          <Descriptions.Item label="Address">{address}</Descriptions.Item>
          <Descriptions.Item label="City">{city}</Descriptions.Item>
          <Descriptions.Item label="State">{state}</Descriptions.Item>
          <Descriptions.Item label="Zip Code">{zip_code}</Descriptions.Item>
          <Descriptions.Item label="Latitude">{latitude}</Descriptions.Item>
          <Descriptions.Item label="Longitude">{longitude}</Descriptions.Item>
          <Descriptions.Item label="Brand">{brand_name}</Descriptions.Item>
          <Descriptions.Item label="Name">{name}</Descriptions.Item>
          <Descriptions.Item label="Original ID">
            {original_id}
          </Descriptions.Item>

          <Descriptions.Item label="MM ID">{id}</Descriptions.Item>

          <Descriptions.Item label="PMS Store Id">{pms_id}</Descriptions.Item>
          <Descriptions.Item label="Is REIT?">
            {JSON.stringify(reit)}
          </Descriptions.Item>

          <Descriptions.Item label="Area">{area}</Descriptions.Item>
          <Descriptions.Item label="Region">{region}</Descriptions.Item>
          <Descriptions.Item label="Division">{division}</Descriptions.Item>

          <Descriptions.Item label="NSA ID">{nsa_id}</Descriptions.Item>

          <Descriptions.Item label="Management Company">
            {management_company}
          </Descriptions.Item>
          <Descriptions.Item label="Region Name">
            {regionName}
          </Descriptions.Item>

          {/* <Descriptions.Item label="Map">{map}</Descriptions.Item> */}
          {/* <Descriptions.Item label="Emergency Code">{emergency_code}</Descriptions.Item> */}
          {/* <Descriptions.Item label="Timezone">{timezone}</Descriptions.Item> */}
          {/* <Descriptions.Item label="Website">{website}</Descriptions.Item> */}
          {/* <Descriptions.Item label="Email Address">{email_address}</Descriptions.Item> */}
          {/* <Descriptions.Item label="Phone Number">{phone_number}</Descriptions.Item> */}
        </Descriptions>
      </Card>
    </>
  );
};

export default DisplayDataWarehouseDetailsObject;
