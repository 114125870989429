import React from 'react';
import ReactDOM from 'react-dom/client';
import { MsalProvider } from "@azure/msal-react";
import { Provider as ReduxProvider } from 'react-redux';
// import { msalInstance } from './msalInstance'; // Import msalInstance
// import { EventType } from '@azure/msal-browser'; // Import EventType
import { PublicClientApplication ,EventType} from '@azure/msal-browser';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App'; 
import { AuthProvider } from './AuthContext'; 
import reportWebVitals from './reportWebVitals';
import './index.css';
// import { setAuthenticated } from './features/auth/authSlice'; // Import the action
import { setAuthenticated, setMmApiKey, setTokenExpiration, setUserEmail, setUserRoles } from './features/auth/authSlice'; // Adjust the path as necessary

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from '../src/redux/store';
// Add event callback to msalInstance

const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_API_CLIENT_ID,
        authority: process.env.REACT_APP_API_AUTHORITY,
        redirectUri: process.env.REACT_APP_REDIRECT_URI
    }
   
  };
  
  const msalInstance = new PublicClientApplication(msalConfig);
  await msalInstance.initialize();

  msalInstance.handleRedirectPromise().then((tokenResponse) => {
    if (tokenResponse && tokenResponse.account) {
        const idToken = tokenResponse.idToken;
        let roles = JSON.stringify(tokenResponse.idTokenClaims?.roles) || "";
        let userEmail = tokenResponse.account?.username || null;
        store.dispatch(setAuthenticated(true));
        store.dispatch(setUserEmail(userEmail));
        store.dispatch(setUserRoles(roles));
        // store.dispatch(setUserEmail("cbongcayao@nsabrands.com"));
        // store.dispatch(setUserRoles(JSON.stringify(["AreaManager064", "AreaManager065", "RegionalManager012"])));
        // store.dispatch(setUserRoles(JSON.stringify(["AreaManager064", "AreaManager065", "RegionalManager012"])));

        // store.dispatch(setUserRoles(JSON.stringify(["StorageSolutions"])));
        // store.dispatch(setUserEmail("0012@nsabrands.com"))
        // store.dispatch(setUserEmail("jswan@msdk.com"))
        // store.dispatch(setUserRoles(JSON.stringify(["asdf"])));
        // store.dispatch(setUserRoles(JSON.stringify(["call_center_agent","aisdfjawwe"])));
        // store.dispatch(setUserRoles("admin"));
        // store.dispatch(setUserRoles("super_admin"));
        // store.dispatch(setUserRoles("director"));

        // store.dispatch(setUserRoles(JSON.stringify(["RegionalManager012"])));
        // store.dispatch(setUserRoles(JSON.stringify(["RegionalManager012"])));
      //  store.dispatch(setUserRoles(JSON.stringify(["AreaManager064","AreaManager002"])));


        // let userName = tokenResponse.account?.name || null;
        // localStorage.setItem('userName', userName)
        // localStorage.setItem('userEmail', userEmail)


        // Call a function to handle the ID token and get the MM API token
        getMmApiToken(idToken);
    }
  }).catch((err) => {
    console.error(err);
  });
  
  const getMmApiToken = async (idToken) => {
    const apiUrl = process.env.REACT_APP_API_URL; 

    try {

      const response = await fetch(`${apiUrl}/auth`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'token': idToken,
            'Authorization': "Bearer " + '123'
        },
        body: JSON.stringify({ token: idToken }),
        credentials: 'same-origin',
    });

  
      if (response.ok) {
        const data = await response.json();
        await store.dispatch(setMmApiKey(data.api_key));
        await store.dispatch(setTokenExpiration(data.payload[0].exp));
        await store.dispatch(setAuthenticated(true)); // Set authenticated to true only after successful API call
      } else {
        console.error('Failed to fetch MM API token');
        alert('Failed to fetch MM API token');

        // Handle failed API call here, e.g., redirect to login or error page
        // You can use navigate('/login') or another approach based on your routing setup
      }
    } catch (error) {
      console.error('Error fetching MM API token:', error);
      alert('Error fetching MM API token:', error);

      // Handle network or other errors here, e.g., redirect or display error message
    }
  };
  
  const WrappedApp = () => (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <Router>
                <App />
            </Router>
        </PersistGate>
    </Provider>
  );
  


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <WrappedApp />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



// every app load 
// 

// app loads, user clicks azure ad login, which redirects, upon redirect back to app react sends the azure token to rails validator, if valid rails sends back apikey which is then passed in all subsequent calls (facility, facilitydetails, etc)

// LOGIN SEQUENCE:
// On load - check localstorage MM token for expiration.
// if expired surface Azure AD login
// else extract MM API Key and use for subsequent requests