import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Spin, Card, Row, Col, Select, Table, Layout, Menu } from "antd";
import Spinner from "../navigation/Spinner";
import * as facilitiesSlice from "../features/facilities/facilitiesSlice";
import * as engrainSlice from "../features/engrain/engrainSlice";
import * as unitsSlice from "../features/units/unitsSlice";
import MapView from "../components/MapView";
const { Header, Content, Sider } = Layout;
const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const additionalData =
    useSelector((state) => state.facilities.additionalData) || [];
  const mmFacilities =
    useSelector((state) => state.facilities.facilities) || [];

  const isLoading = useSelector((state) => state.loading.isLoading);
  const [facilityDetailsLoading, setFacilityDetailsLoading] = useState(false);
  const [filteredFacilities, setFilteredFacilities] = useState([]);
  const [hoveredRecord, setHoveredRecord] = useState(null);
  const [mergedData, setMergedData] = useState([]);
  const [collapsed, setCollapsed] = useState(false);

  const [attributes, setAttributes] = useState({
    area: undefined,
    region: undefined,
    division: undefined,
    city: undefined,
    state: undefined,
    reit: undefined,
    pmsType: undefined,
    brandName: undefined,
    managementCompany: undefined,
    ownership: undefined,
  });
  const [selectOptions, setSelectOptions] = useState({
    areas: [],
    regions: [],
    divisions: [],
    citys: [],
    states: [],
    reits: [],
    pms_types: [],
    brand_names: [],
    management_companys: [],
    ownerships: [],
  });

  const { Option } = Select;

  useEffect(() => {
    dispatch(facilitiesSlice.updateSelectedFacilityDetails(null));
    dispatch(facilitiesSlice.fetchFacilities());
    dispatch(facilitiesSlice.fetchAdditionalData());
  }, [dispatch]);

  useEffect(() => {
    const mergeData = () => {
      const additionalDataMap = new Map(
        additionalData.map((item) => [item.id, item])
      );
    
      const merged = mmFacilities.map((item) => {
        const additionalItem = additionalDataMap.get(item.id);
    
        if (additionalItem) {
          // Only add properties from additionalItem that are not already in item
          const nonOverlappingProperties = Object.keys(additionalItem).reduce(
            (acc, key) => {
              if (!(key in item)) {
                acc[key] = additionalItem[key];
              }
              return acc;
            },
            {}
          );
    
          return { ...item, ...nonOverlappingProperties };
        }
    
        return { ...item };
      });
    
      setMergedData(merged);
    };

    if (mmFacilities.length && additionalData.length) {
      mergeData();
    }
  }, [mmFacilities, additionalData]);

  useEffect(() => {
    if (mergedData.length) {
      console.log(mergedData[14]);
      filterFacilities();
    }
  }, [mergedData, attributes]);

  const filterFacilities = () => {
    console.log("ADD", mergedData.length);

    const filtered = mergedData.filter((facility) => {
      return Object.keys(attributes).every((attr) => {
        if (attributes[attr] === "null") {
          // Check explicitly for null values in the facility
          return facility[attr] === null;
        } else if (attributes[attr] === undefined || attributes[attr] === "") {
          // Ignore attributes that are undefined or empty strings
          return true;
        } else {
          // Check for non-null and non-undefined attributes and match them
          return (
            facility[attr] !== null &&
            facility[attr] !== undefined &&
            facility[attr].toString() === attributes[attr]
          );
        }
      });
    });

    console.log("FIL", filtered.length);

    const calcFiltered = filtered.map((cf) => {
      const vacantUnits = cf.units - cf.occupiedUnits; // Calculate outside the object creation
      const delinquencyRate = (cf.delinquentCount * 100) / cf.occupiedUnits; // Calculate outside the object creation
      return {
        ...cf,
        vacantUnits,
        delinquencyRate,
      };
    });
    console.log("CAL", calcFiltered.length);
    setFilteredFacilities(calcFiltered);
    countFacilityAttributes(calcFiltered);
  };

  const countFacilityAttributes = (facilities) => {
    const counts = Object.keys(selectOptions).reduce((acc, key) => {
      acc[key] = {};
      return acc;
    }, {});
    facilities = facilities.map((facility) => {
      let updatedFacility = { ...facility }; // Create a shallow copy of the facility object

      // Iterate over all keys in the facility object
      Object.keys(updatedFacility).forEach((key) => {
        if (updatedFacility[key] === true) {
          updatedFacility[key] = "true";
        } else if (updatedFacility[key] === false) {
          updatedFacility[key] = "false";
        } else if (updatedFacility[key] === null) {
          updatedFacility[key] = "null";
        } else if (updatedFacility[key] === undefined) {
          updatedFacility[key] = "undefined";
        }
      });

      return updatedFacility;
    });

    // Now you can proceed with counting or other operations
    Object.keys(counts).forEach((pluralKey) => {
      facilities.forEach((facility) => {
        let attribute = pluralKey.slice(0, -1); // Assuming pluralKey ends in 's'

        if (facility[attribute] != null) {
          counts[pluralKey][facility[attribute]] =
            (counts[pluralKey][facility[attribute]] || 0) + 1;
        }
      });
    });

    const newSelectOptions = Object.keys(counts).reduce((acc, key) => {
      acc[key] = Object.keys(counts[key]).map((attrKey) => ({
        key: attrKey,
        value: counts[key][attrKey],
      }));
      return acc;
    }, {});
    setSelectOptions(newSelectOptions);
  };

  const handleAttributeChange = (attribute, value) => {
    setAttributes((prev) => ({ ...prev, [attribute]: value }));
  };

  // Render Select components for all attributes
  const renderSelects = (group) => {
    if (group.includes("area")) {
      return group.map((attribute) => (
        <Menu.Item>
          <Select
            key={attribute}
            onChange={(value) => handleAttributeChange(attribute, value)}
            allowClear
            placeholder={`${attribute === "pms_type" ? "PMS" : attribute === "brand_name" ? "Brand" : attribute === "management_company" ? "MGMT Company" : attribute === "reit" ? attribute.toUpperCase() : attribute.charAt(0).toUpperCase() + attribute.slice(1)} (${selectOptions[`${attribute}s`] ? selectOptions[`${attribute}s`].length : 0})`}
            style={{ width: "100%", marginBottom: 8 }}
          >
            {selectOptions[`${attribute}s`]
              ? selectOptions[`${attribute}s`].map((option) => (
                  <Option
                    key={option.key}
                    value={option.key}
                  >{`${attribute === "pms_type" ? "PMS" : attribute === "brand_name" ? "Brand" : attribute === "management_company" ? "MGMT Company" : attribute === "reit" ? attribute.toUpperCase() : attribute.charAt(0).toUpperCase() + attribute.slice(1)}: ${option.key} (${option.value})`}</Option>
                ))
              : null}
          </Select>
        </Menu.Item>
      ));
    } else {
      return group.map((attribute) => (
        <Menu.Item>
          <Select
            key={attribute}
            onChange={(value) => handleAttributeChange(attribute, value)}
            allowClear
            placeholder={`${attribute === "pms_type" ? "PMS" : attribute === "brand_name" ? "Brand" : attribute === "management_company" ? "MGMT Company" : attribute === "reit" ? attribute.toUpperCase() : attribute.charAt(0).toUpperCase() + attribute.slice(1)} (${selectOptions[`${attribute}s`] ? selectOptions[`${attribute}s`].length : 0})`}
            style={{ width: "100%", marginBottom: 8 }}
          >
            {selectOptions[`${attribute}s`]
              ? selectOptions[`${attribute}s`]
                  .sort((a, b) => a.key.localeCompare(b.key))
                  .map((option) => (
                    <Option
                      key={option.key}
                      value={option.key}
                    >{`${attribute === "pms_type" ? "PMS" : attribute === "brand_name" ? "Brand" : attribute === "management_company" ? "MGMT Company" : attribute === "reit" ? attribute.toUpperCase() : attribute.charAt(0).toUpperCase() + attribute.slice(1)}: ${option.key} (${option.value})`}</Option>
                  ))
              : null}
          </Select>
        </Menu.Item>
      ));
    }
  };

  const calculateData = (data) => {
    let totalOccupancyRate = 0,
      totalMonthlyOpportunity = 0,
      totalMonthlyRevenue = 0,
      totalDelinquencyRate = 0;
    data.forEach((item) => {
      const occupancyRate = parseFloat(item.occupancy_rate || 0);
      const delinquencyRate = parseFloat(item.delinquencyRate || 0);
      const monthlyOpportunity = parseFloat(item.monthly_opportunity || 0);
      const monthlyRevenue = parseFloat(item.monthly_revenue || 0);
      if (!isNaN(occupancyRate)) {
        totalOccupancyRate += occupancyRate;
      }
      if (!isNaN(monthlyOpportunity)) {
        totalMonthlyOpportunity += monthlyOpportunity;
      }
      if (!isNaN(delinquencyRate)) {
        totalDelinquencyRate += delinquencyRate;
      }
      if (!isNaN(monthlyRevenue)) {
        totalMonthlyRevenue += monthlyRevenue;
      }
    });
    const averageOccupancyRate =
      data.length > 0 ? (totalOccupancyRate / data.length).toFixed(2) : "0.00";
    const formattedTotalMonthlyOpportunity =
      totalMonthlyOpportunity.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
      });
    const formattedDelinquencyRate =
      data.length > 0
        ? (totalDelinquencyRate / data.length).toFixed(2)
        : "0.00";
    const formattedTotalMonthlyRevenue = totalMonthlyRevenue.toLocaleString(
      "en-US",
      { style: "currency", currency: "USD" }
    );
    return {
      averageOccupancyRate,
      formattedTotalMonthlyOpportunity,
      formattedTotalMonthlyRevenue,
      formattedDelinquencyRate,
    };
  };

  const columns = [
    {
      title: "Facility",
      dataIndex: "name",
      key: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Units",
      dataIndex: "units",
      key: "units",
      sorter: (a, b) => a.units - b.units,
    },
    {
      title: "Occupied",
      dataIndex: "occupiedUnits",
      key: "occupiedUnits",
      sorter: (a, b) => a.occupiedUnits - b.occupiedUnits,
    },
    {
      title: "Vacant",
      dataIndex: "vacantUnits",
      key: "vacantUnits",
      sorter: (a, b) => a.vacantUnits - b.vacantUnits,
    },
    {
      title: "Delinquent",
      dataIndex: "delinquentCount",
      key: "delinquentCount",
      sorter: (a, b) => a.delinquentCount - b.delinquentCount,
    },
    {
      title: "Delinquency",
      dataIndex: "delinquencyRate",
      key: "delinquencyRate",
      sorter: (a, b) => a.delinquencyRate - b.delinquencyRate,
      render: (text) => `${parseFloat(text).toFixed(2)}%`,
    },
    {
      title: "Occupancy",
      dataIndex: "occupancy_rate",
      key: "occupancy_rate",
      sorter: (a, b) => a.occupancy_rate - b.occupancy_rate,
      render: (text) => `${parseFloat(text).toFixed(2)}%`,
    },
    {
      title: "Revenue",
      dataIndex: "monthly_revenue",
      key: "monthly_revenue",
      sorter: (a, b) => a.monthly_revenue - b.monthly_revenue,
      render: (text) =>
        `${parseFloat(text).toLocaleString("en-US", { style: "currency", currency: "USD" })}`,
    },
    {
      title: "Opportunity",
      dataIndex: "monthly_opportunity",
      key: "monthly_opportunity",
      sorter: (a, b) => a.monthly_opportunity - b.monthly_opportunity,
      render: (text) =>
        `${parseFloat(text).toLocaleString("en-US", { style: "currency", currency: "USD" })}`,
    },
  ];

  const handleView = async (record) => {
    setFacilityDetailsLoading(true);
    try {
      const facilityDetailsResponse = await dispatch(
        facilitiesSlice.fetchFacilityDetails({
          brand: record.brand,
          id: record.id,
        })
      ).unwrap();
      dispatch(
        facilitiesSlice.updateSelectedFacilityDetails(facilityDetailsResponse)
      );
      await dispatch(engrainSlice.fetchUnits());
      await dispatch(engrainSlice.fetchFloors());
      await dispatch(unitsSlice.fetchUnits(record.id));
      setFacilityDetailsLoading(false);
      navigate(`/facilities/${record.id}`);
    } catch (error) {
      console.error("Error fetching facility details:", error);
      setFacilityDetailsLoading(false);
    }
  };

  if (isLoading) {
    return <Spinner size="large" />;
  }

  const {
    averageOccupancyRate,
    formattedTotalMonthlyOpportunity,
    formattedTotalMonthlyRevenue,
    formattedDelinquencyRate,
  } = calculateData(filteredFacilities);

  return (
    <Layout style={{ width: "100%" }}>
      <Sider
        width={300}
        collapsible
        collapsed={collapsed}
        onCollapse={setCollapsed}
      >
        <Menu
          mode="inline"
          defaultSelectedKeys={["1"]}
          defaultOpenKeys={["sub2"]}
          style={{ height: "100%", borderRight: 0 }}
        >
          {/* Add your menu items here */}
          <Menu.ItemGroup>
            <h4 style={{ padding: "25px 25px 0px 25px" }}>
              Division, Region, Area
            </h4>
            {renderSelects(["division", "region", "area"])}
          </Menu.ItemGroup>
          <Menu.ItemGroup>
            <h4 style={{ padding: "25px 25px 0px 25px" }}>
              Reit, Ownership, Management
            </h4>
            {renderSelects(["reit", "ownership", "management_company"])}
          </Menu.ItemGroup>
          <Menu.ItemGroup>
            <h4 style={{ padding: "25px 25px 0px 25px" }}>PMS, Brand</h4>
            {renderSelects(["pms_type", "brand_name"])}
          </Menu.ItemGroup>
          <Menu.ItemGroup>
            <h4 style={{ padding: "25px 25px 0px 25px" }}>State, City</h4>
            {renderSelects(["state", "city"])}
          </Menu.ItemGroup>
        </Menu>
      </Sider>

      <Layout style={{ padding: "0 15px 15px" }}>
        <Card>
          <Row gutter={16}>
            <Col span={6}>
              <Card title="Occupancy Rate">{averageOccupancyRate}%</Card>
            </Col>
            <Col span={6}>
              <Card title="Delinquency Rate">{formattedDelinquencyRate}%</Card>
            </Col>
            <Col span={6}>
              <Card title="Monthly Revenue">
                {formattedTotalMonthlyRevenue}
              </Card>
            </Col>
            <Col span={6}>
              <Card title="Monthly Opportunity">
                {formattedTotalMonthlyOpportunity}
              </Card>
            </Col>
          </Row>
          <MapView
            facilities={filteredFacilities}
            hoveredRecord={hoveredRecord}
          />
          <Table
            dataSource={filteredFacilities}
            columns={columns}
            rowKey="id"
            onRow={(record, rowIndex) => {
              return {
                onMouseEnter: () => {
                  setHoveredRecord(record);
                },
                onMouseLeave: () => {
                  setHoveredRecord(null);
                },
                onClick: () => {
                  handleView(record);
                },
                // Add other event handlers as needed
              };
            }}
          />
        </Card>
      </Layout>
    </Layout>
  );
};

export default Home;
