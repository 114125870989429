import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Tag, Space, Input, Button, message } from "antd";
import {
  fetchStatuses,
  deleteStatus,
} from "../features/facilities/statusSlice"; // Adjust import path
import StatusForm from "./StatusForm";
import moment from "moment";

const StatusTable = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = React.useState("");
  const [showForm, setShowForm] = React.useState(false);
  const [currentStatus, setCurrentStatus] = React.useState(null);
  const facilityId = useSelector(
    (state) => state.facilities.selectedFacility.id
  );
  const brandId = useSelector(
    (state) => state.facilities.selectedFacility.brand_id
  );
  const email = useSelector((state) => state.auth.userEmail);
  const roles = useSelector((state) => state.auth.userRoles); // Assuming roles are stored in auth state
  useEffect(() => {
    console.log("Facility ID:", facilityId);
    if (facilityId) {
      dispatch(fetchStatuses(facilityId));
    }
  }, [dispatch, facilityId]);

  // Access the fetched statuses and loading state from Redux
  const { statuses, loading } = useSelector((state) => state.status);

  // Event handlers
  const toggleCreateForm = () => {
    setCurrentStatus(null);
    setShowForm(true);
  };

  const toggleTableView = () => {
    setShowForm(false);
  };

  const onEdit = (record) => {
    setCurrentStatus(record);
    setShowForm(true);
  };

  const onDelete = (facilityId, statusId) => {
    dispatch(deleteStatus({ facilityId, statusId })) // Pass an object with facilityId and statusId
      .then(() => {
        message.success("Status deleted successfully");
        dispatch(fetchStatuses(facilityId)); // Refresh statuses list
      })
      .catch((error) => {
        message.error(`Failed to delete status: ${error.message}`);
      });
  };

  // Filter data based on the search text
  const filteredStatuses = searchText
    ? statuses.filter((item) =>
        Object.values(item).some((value) =>
          value.toString().toLowerCase().includes(searchText.toLowerCase())
        )
      )
    : statuses;

  // Mapping for status_type values to human-readable text
  const statusTypeMapping = {
    1: "Store Closure",
    2: "Access Issue",
    3: "Technology Issue",
    4: "Custom",
  };

  // Define the columns including the Edit and Delete actions
  const columns = [
    {
      title: "Type",
      dataIndex: "status_type",
      key: "type",
      render: (type, record) => {
        // Check if the type is 'Custom' and there is a custom_message
        if (type === 4 && record.custom_message) {
          return record.custom_message; // Display custom message
        }
        return statusTypeMapping[type] || type; // Fallback to mapped type or raw type
      },
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      render: (state) => (
        <Tag color={state === "Current" ? "green" : "geekblue"}>
          {state ? state.toUpperCase() : "N/A"}
        </Tag>
      ),
    },
    {
      title: "Start Date",
      dataIndex: "start_date", // Updated from start_time to start_date
      key: "startDate",
      render: (start) =>
        start ? moment(start).format("ddd DD MMM, YYYY") : "N/A",
    },
    {
      title: "End Date",
      dataIndex: "end_date", // Updated from end_time to end_date
      key: "endDate",
      render: (end) => (end ? moment(end).format("ddd DD MMM, YYYY") : "N/A"),
    },
  ];

  // Check if the user has one of the required roles

  if (
    roles.toLowerCase().includes("regional") ||
    roles.toLowerCase().includes("area") ||
    roles.toLowerCase().includes("call_center_agent") ||
    roles.toLowerCase().includes("admin") ||
    roles.toLowerCase().includes("director") ||
    roles.toLowerCase().includes("operator"))
   {
    columns.push({
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <Button onClick={() => onEdit(record)}>Edit</Button>
          <Button danger onClick={() => onDelete(facilityId, record.id)}>
            Delete
          </Button>
        </Space>
      ),
    });
  }

  // Search bar input
  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  return (
    <>
      {showForm ? (
        <StatusForm
          mode={currentStatus ? "edit" : "create"}
          initialValues={currentStatus}
          onBack={toggleTableView}
        />
      ) : (
        <>
          <Space
            style={{
              marginBottom: 16,
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Input
              placeholder="Search store statuses..."
              value={searchText}
              onChange={handleSearch}
            />
           {(roles.toLowerCase().includes("regional") ||
              roles.toLowerCase().includes("area") ||
              roles.toLowerCase().includes("call_center_agent") ||
              roles.toLowerCase().includes("admin") ||
              roles.toLowerCase().includes("director") ||
              (roles.toLowerCase().includes("operator"))) && (
              <Button type="primary" onClick={toggleCreateForm}>
                Create New Status
              </Button>
            )}
          </Space>
          <Table
            loading={loading}
            columns={columns}
            dataSource={filteredStatuses.map((status, index) => ({
              ...status,
              key: index,
            }))}
          />
        </>
      )}
    </>
  );
};

export default StatusTable;
