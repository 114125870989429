
import React, { useEffect,useState } from 'react';

import { Tabs, Layout, Card,Alert, Space } from 'antd';
import { useParams } from 'react-router-dom';
import reduxStore from "../redux/store"
import { useSelector,Provider } from 'react-redux'
import store from "../redux/store"
import { useDispatch } from 'react-redux';
import * as facilitiesSlice from '../features/facilities/facilitiesSlice';
import { fetchStatuses } from '../features/facilities/statusSlice';


import DisplayObject from './FacilityDisplay'

const { Header, Content, Footer, Sider } = Layout;



const FacilityOverview = () => {
  const dispatch = useDispatch();
  const { loading, statuses, error } = useSelector((state) => state.status);
  const { id } = useParams(); // Extract facility ID from the URL
  const selectedFacility = useSelector((state) => state.facilities.selectedFacility);
  const [statusData, setStatusData] = useState([]);

  const truncateString = (str, num) => {
    if (str.length > num) {
      return str.slice(0, num) + '...'; // Truncate and add ellipsis
    }
    return str;
  };
  
  useEffect(() => {
    const facilityId = selectedFacility?.id;
    if (facilityId && selectedFacility.statuses) {
      const response = selectedFacility.statuses;
      const statusTypeMapping = {
        1: { message: "Store Closure", type: "success" },
        2: { message: "Access Issue", type: "info" },
        3: { message: "Technology Issue", type: "warning" },
        4: { message: "Custom", type: "info" },
      };
  
      const transformedData = response.map((status) => {
        // Determine the message to display based on status_type
        // let statusMessage = status.status_type === 4 && status.custom_message
        //   ? status.custom_message  // Use custom message for custom types
        //   : statusTypeMapping[status.status_type].message;  // Use mapped message for other types
        
          let statusMessage = status.status_type === 4 && status.custom_message
          ? truncateString(status.custom_message, 30)  // Truncate custom messages
          : statusTypeMapping[status.status_type].message;  // Use mapped message for other types
  
        // Determine the alert type to display
        const alertType = statusTypeMapping[status.status_type].type;
  
        return {
          ...status,
          statusMessage: statusMessage,
          alertType: alertType,
        };
      });
  
      console.log('transformedData', transformedData);
      setStatusData(transformedData);
      dispatch(fetchStatuses(facilityId));
    }
  }, [id, selectedFacility, dispatch]);
  

  return (
    <Layout hasSider>
        <Card 
            bordered={true}
            className='layoutStyle'
            title={selectedFacility ? selectedFacility.name : 'Loading...'} 
            extra={
              <Space> 
                {statusData.map((status, index) => {
                  if (status.statusMessage){
                    return(
                      <>
                      <Alert key={index} message={status.statusMessage} type={status.alertType} showIcon />
                      </>
                    )
                  }else{
                    return (
                      <div />
                    )
                  }
            })}
              </Space>
            }
            
        >
            <Content>
                {selectedFacility && <DisplayObject selectedFacility={selectedFacility} />}
            </Content>
        </Card>
    </Layout>
  ); 
};
  export default FacilityOverview;
