import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { setLoading } from '../auth/loadingSlice';
import { useSelector } from 'react-redux';

const initialState = {
    loading: false,
    isLoaded: false,
    units: [],
    error: '',
    occupancyRate: null,
    monthlyRevenue: null,
    monthlyOpportunity: null
}

const apiUrl = process.env.REACT_APP_API_URL;

const fetchUnits = createAsyncThunk(
    'units/fetchUnits',
    async (facilityID, thunkAPI) => {
        const mmApiKey = thunkAPI.getState().auth.mmApiKey;
    
        console.log('before try', facilityID);

        try {
            const response = await fetch(`${apiUrl}/v1/facilities/${facilityID}/units`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + mmApiKey
                },
                credentials: "same-origin",
            });

            console.log('response', response);

            if (!response.ok) {
                throw new Error('Failed to fetch facilities');
            }

            const units = await response.json();            
            const totalUnits = units.length;
            const occupiedUnits = units.filter(unit => !unit.available).length;
            const occupancyRate = (occupiedUnits / totalUnits) * 100;

            let monthlyRevenue = 0;
            let monthlyOpportunity = 0;
            units.forEach(unit => {
                if (unit.available) {
                    monthlyOpportunity += unit.price.street_rate;
                } else {
                    monthlyRevenue += unit.price.street_rate;
                }
            });

            const result = {
                units: units,
                occupancyRate: occupancyRate.toFixed(2),
                monthlyRevenue: monthlyRevenue.toFixed(2),
                monthlyOpportunity: monthlyOpportunity.toFixed(2)
            };

            return result;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

const unitsSlice = createSlice({
    name: 'units',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchUnits.pending, state => {
            console.log('fetchUnits.pending');
            state.loading = true;
            state.isLoaded = false;
        });
        builder.addCase(fetchUnits.fulfilled, (state, action) => {
            console.log('fetchUnits.fulfilled');
            state.loading = false;
            state.isLoaded = true;
            state.units = action.payload.units;
            state.occupancyRate = action.payload.occupancyRate;
            state.monthlyRevenue = action.payload.monthlyRevenue;
            state.monthlyOpportunity = action.payload.monthlyOpportunity;
            state.error = '';
        });
        builder.addCase(fetchUnits.rejected, (state, action) => {
            console.log('fetchUnits.rejected', action.error);
            state.loading = false;
            state.isLoaded = false;
            state.units = [];
            state.error = action.error.message;
        });
    }
});

export { unitsSlice, fetchUnits };
