import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Spin, Card } from 'antd';
import { WindowsOutlined, LockOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { loginUser } from '../features/auth/authSlice';
import store from '../redux/store'; // Import Redux store

// e } from 'react';
// import { useMsal } from '@azure/msal-react';
// import { Button, Divider } from 'antd';
// import { WindowsOutlined, LockOutlined } from '@ant-design/icons';
import './LoginForm.css'; // Import antd CSS
// import Spinner from '../navigation/Spinner';
// import { useSelector } from 'react-redux';


export const SignInButton = () => {

      // Styles object
      const styles = {
        container: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh', // Full height of the viewport
            flexDirection: 'column', // Stack items vertically
        },
        button: {
            // Additional styling for the button if needed
        }
    };

    // Styles for the Ant Design card
const cardStyle = {
    textAlign: 'center',
    width: 300, // Adjust width as needed
    padding: '20px'
};

// Styles for the "Logging in" text
const loginTextStyle = {
    marginTop: '20px',
    fontSize: '16px',
    color: '#000', // or any color you prefer
};
// Styles for the spinner container
const spinnerContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh', // Full height of the viewport
};


    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLoading = useSelector((state) => state.auth.isLoading); // Replace with your actual loading state path
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated); // Replace with your actual auth state path

    useEffect(() => {
        if (isAuthenticated) {
            navigate('/');
        }
    }, [isAuthenticated, navigate]);

    const handleLogin = () => {
        store.dispatch(loginUser());
    };

    if (isLoading) {
        return (
            <div style={spinnerContainerStyle}>
                <Card style={cardStyle}>
                    <Spin size="large" />
                    <div style={loginTextStyle}>Logging in...</div>
                </Card>
            </div>
        );
    }

    return (
        <>
        <div className="login-container">
        <header className="app-header">
            <div className="app-logo"> </div>
            <h1>Mega Mind Console</h1>
            <div className="sso-enabled">
                <LockOutlined /> SINGLE SIGN-ON ENABLED
            </div>
        </header>
        <div className="login-methods">
            <Button type="primary" onClick={handleLogin} block icon={<WindowsOutlined />}>
                LOG IN WITH MICROSOFT ACCOUNT...
            </Button>
        </div>
        </div>
    </>
    );
};







// import React, { useEffect,useState } from 'react';
// import { useMsal } from '@azure/msal-react';
// import { Button, Divider } from 'antd';
// import { WindowsOutlined, LockOutlined } from '@ant-design/icons';
// import './LoginForm.css'; // Import antd CSS
// import Spinner from '../navigation/Spinner';
// import { useSelector } from 'react-redux';

// export const SignInButton = () => {
  

//   const [loginRetry, setLoginRetry] = useState(sessionStorage.getItem('refreshlogin')); 

//     const { instance } = useMsal();
//     const isLoading = useSelector((state) => state.loading.isLoading);

//     useEffect(() => {
//         console.log('loginRetry',loginRetry);
//     }, [isLoading,loginRetry]);

//     const handleSignIn = () => {
//         instance.loginRedirect({
//             scopes: ['user.read'],
//         });
//     };

//     // Styles object
//     const styles = {
//         container: {
//             display: 'flex',
//             justifyContent: 'center',
//             alignItems: 'center',
//             height: '100vh', // Full height of the viewport
//             flexDirection: 'column', // Stack items vertically
//         },
//         button: {
//             // Additional styling for the button if needed
//         }
//     };

//     const spinnerContainerStyle = {
//       display: 'flex',
//       justifyContent: 'center',
//       alignItems: 'center',
//       height: '100vh', // Full height of the viewport
//   };

//   if (isLoading) {
//     return (
//         <div style={spinnerContainerStyle}>
//             <Spinner />
//         </div>
//     );
// }

// return (
//   <div className="login-container">
//       {loginRetry==="true" ? (
//           <>
//           <header className="app-header">
//           <div className="app-logo"> </div>
//           {/* <h1>ssMega Mind Console</h1> */}
//           <div className="sso-enabled">
//               {/* <LockOutlined /> SINGLE SIGN-ON ENABLED */}
//           <div className="error-message">
//               Something went wrong. Please try again.
//           </div>
//           </div>
//           </header>
//           <div className="login-methods">
//                   <Button type="primary" onClick={handleSignIn} block icon={<WindowsOutlined />}>
//                       LOG IN WITH MICROSOFT ACCOUNT...
//                   </Button>
//               </div>
//           </>
//       ) : (
//           <>
//               <header className="app-header">
//                   <div className="app-logo"> </div>
//                   <h1>qqqMega Mind Console</h1>
//                   <div className="sso-enabled">
//                       <LockOutlined /> SINGLE SIGN-ON ENABLED
//                   </div>
//               </header>
//               <div className="login-methods">
//                   <Button type="primary" onClick={handleSignIn} block icon={<WindowsOutlined />}>
//                       LOG IN WITH MICROSOFT ACCOUNT...
//                   </Button>
//               </div>
//           </>
//       )}
//   </div>
// );
// };