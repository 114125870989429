import React, { useEffect, useState } from 'react';
import { Form, message, Radio, DatePicker, Select, Switch, Input, Button, Row, Col, Typography, Card, TimePicker } from 'antd';
import moment from 'moment-timezone';
import { useSelector, useDispatch } from 'react-redux';
import { createStatus, updateStatus } from '../features/facilities/statusSlice';

const { Title, Paragraph } = Typography;
const { Option } = Select;

function getStatusAutoExpiry(initialValues) {
  return initialValues && typeof initialValues.autoExpiry === 'boolean' ? initialValues.autoExpiry : false;
}


const StatusForm = ({ onBack, initialValues, mode }) => {
  console.log('initialValuse',initialValues)
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const facilityId = useSelector((state) => state.facilities.selectedFacility.id);
  const facilityTimezone = useSelector((state) => state.facilities.selectedFacility.timezone);
  const isEditMode = mode === 'edit';
  const [validTimeZone, setValidTimeZone] = useState(facilityTimezone?true:false)
  const [autoExpiry, setAutoExpiry] = useState(getStatusAutoExpiry(initialValues));

  useEffect(() => {
    if (isEditMode && initialValues) {
      const durationValue = initialValues.all_day ? 'all-day' : 'modified-hours';
      
      const openTimeLocal = initialValues.open_time ?  moment(`${initialValues.start_date} ${initialValues.open_time}`) : null;
      const closeTimeLocal = initialValues.close_time ? moment(`${initialValues.start_date} ${initialValues.close_time}`) : null;
  
      const initialValuesWithMoment = {
        ...initialValues,
        type: reverseMapStatusType(initialValues.status_type),
        startDate: initialValues.start_date ? moment(initialValues.start_date) : null,
        endDate: initialValues.end_date ? moment(initialValues.end_date) : null,
        expectedResolutionTime: initialValues.expected_resolution ? moment(initialValues.expected_resolution) : null,
        openTime: openTimeLocal,
        closeTime: closeTimeLocal,
        accessDetails: reverseMapAccessIssueDetails(initialValues.access_issue_details),
        technologyIssue: reverseMapTechnologyIssueDetails(initialValues.technology_issue_details),
        customMessage: initialValues.custom_message,
        duration: durationValue,
        alternateContactNumber: initialValues.alt_contact_number,
        autoExpiry:initialValues.auto_expiry
      };

      form.setFieldsValue(initialValuesWithMoment);
      setAutoExpiry(initialValues.auto_expiry);
    }
  }, [form, initialValues, isEditMode, facilityTimezone]);

  const onAutoExpiryChange = (checked) => {
    setAutoExpiry(checked);
    if (checked) {
      const startDateValue = form.getFieldValue('startDate');
      if (startDateValue) {
        form.setFieldsValue({ endDate: startDateValue.clone().startOf('day') });
      }
    } else {
      form.setFieldsValue({ endDate: null });
    }
  };

  // const handleTimeChange = (time, timeString) => {
  //   const timeInFacilityTimezone = moment.tz(timeString, "HH:mm", facilityTimezone);
  //   console.log("Time in Facility Timezone:", timeInFacilityTimezone.format());
  // };


// Assuming reverseMapTechnologyIssueDetails is implemented correctly
const reverseMapTechnologyIssueDetails = (technologyIssueDetails) => {
  const reverseMapping = {
    1: 'The Store Phone Is Offline.',
    2: 'The Store Cell Phone Is Offline.',
    3: 'The Computers Or Internet Are Offline.',
    4: 'The Gate Or Building Keypads Are Offline.',
  };
  return reverseMapping[technologyIssueDetails] || technologyIssueDetails;
};

let openTimeUTC = null
let closeTimeUTC = null
const onFinish = (values) => {

  // console.log("values:", values.openTime);
  if (!values.openTime) {
    console.log('values.openTime is undefined or null');
} else if (!moment(values.openTime).isValid()) {
    console.log('values.openTime is not a valid date:', values.openTime);
} else if (!moment.tz.zone(facilityTimezone)) {
    console.log('facilityTimezone is not valid:', facilityTimezone);
    window.confirm("Please Add Facility Timezone")
} 

openTimeUTC = values?.openTime ? values.openTime.format('HH:mm:ss') : null;
closeTimeUTC = values?.closeTime ? values.closeTime.format('HH:mm:ss') : null;

console.log("Open Time (UTC):", openTimeUTC);
console.log("Close Time (UTC):", closeTimeUTC);

  if (openTimeUTC === "Invalid date" || closeTimeUTC === "Invalid date") {
    console.error("Conversion error: Invalid date");
    // Handle error appropriately
    return;
  }
 
  const formattedValues = {
    facility_id: facilityId,
    status_type: mapStatusType(values.type),
    start_date: values.startDate ? values.startDate.format('YYYY-MM-DD') : null,
    end_date: values.autoExpiry && values.endDate ? values.endDate.format('YYYY-MM-DD') : null,
    auto_expiry: values.autoExpiry,
    all_day: values.duration === 'all-day',
    open_time: openTimeUTC,
    close_time: closeTimeUTC,
    access_issue_details: values.accessDetails ? parseInt(values.accessDetails) : null,
    technology_issue_details: values.technologyIssue ? parseInt(values.technologyIssue) : null,
    alt_contact_number: values.alternateContactNumber,
    expected_resolution: values.expectedResolutionTime ? values.expectedResolutionTime.format('YYYY-MM-DD') : null,
    custom_message: values.customMessage,
  };

  const operationPromise = isEditMode
    ? dispatch(updateStatus({
        facilityId,
        statusId: initialValues.id,
        statusData: formattedValues,
      }))
    : dispatch(createStatus({
        facilityId,
        statusData: formattedValues,
      }));

  operationPromise.then(() => {
    onBack();
    message.success(isEditMode ? 'Status updated successfully' : 'Status created successfully');
  }).catch((error) => {
    message.error(`Failed to ${isEditMode ? 'update' : 'create'} status: ${error.message || 'Unknown error'}`);
  });
};


  // Mapping function to convert status type from string to integer
const mapStatusType = (statusType) => {
  const statusTypeMapping = {
    'store-closure': 1,
    'access-issue': 2,
    'technology-issue': 3,
    'custom': 4,
  };
  return statusTypeMapping[statusType] || statusType;
};


// Reverse mapping functions to convert integers back to strings for form initialization
const reverseMapStatusType = (statusType) => {
  const reverseMapping = {
    1: 'store-closure',
    2: 'access-issue',
    3: 'technology-issue',
    4: 'custom',
  };
  return reverseMapping[statusType] || statusType;
};

const reverseMapAccessIssueDetails = (accessIssue) => {
  const reverseMapping = {
    1: 'The Gate Will Be Stuck Open.',
    2: 'The Gate Will Be Open Office Hours Only.',
    3: 'The Gate Will Not Open And Customers Cannot Access The Property.',
    4: 'The Gate Will Not Open, But Customers Can Access The Property Through An Alternative Entrance.',
    5: 'There Are Elevator(s)/Lift Issues.',
    6: 'There Are Building Access Issues.',
  };
  return reverseMapping[accessIssue] || accessIssue;
};
  return (
    <Card bordered={true} className="layoutStyle">
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Title level={2}>{isEditMode ? 'Edit Status' : 'Create A New Status'}</Title>
        <Paragraph>
          Let the contact center know about temporary events at the store.
        </Paragraph>
         <Form.Item name="type" label="Type" rules={[{ required: true, message: 'Please select the status type!' }]}>
           <Radio.Group>
             <Radio.Button value="store-closure">Store Closure</Radio.Button>
             <Radio.Button value="access-issue">Access Issue</Radio.Button>
             <Radio.Button value="technology-issue">Technology Issue</Radio.Button>
             <Radio.Button value="custom">Custom</Radio.Button>
           </Radio.Group>
         </Form.Item>
         <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.type !== currentValues.type || prevValues.duration !== currentValues.duration}>
         {({ getFieldValue }) => {
            if (getFieldValue('type') === 'access-issue') {
              return (
                <Card title="Access Issue" style={{ width: '60%' }} extra={<p>Details of any gate or access issues currently impacting the store.</p>}>
                  <Form.Item name="accessDetails" label="Details" rules={[{ required: true, message: 'Please select the specific access issue.' }]}>
                    <Select placeholder="Select the specific access issue">
                      <Option value="1">The Gate Will Be Stuck Open.</Option>
                      <Option value="2">The Gate Will Be Open Office Hours Only.</Option>
                      <Option value="3">The Gate Will Not Open And Customers Cannot Access The Property.</Option>
                      <Option value="4">The Gate Will Not Open, But Customers Can Access The Property Through An Alternative Entrance.</Option>
                      <Option value="5">There Are Elevator(s)/Lift Issues.</Option>
                      <Option value="6">There Are Building Access Issues.</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item name="expectedResolutionTime" label="Expected Resolution Time" rules={[{ required: true, message: 'Please select expected resolution time.' }]}>
                    <DatePicker style={{ width: '50%' }} />
                  </Form.Item>
                </Card>
              );
            } else if(getFieldValue('type') === 'store-closure'){
              return (
              <Card title="Store Closure" style={{ width: '50%' } } extra={<p>Temporary changes to your opening hours.</p>}>
                <Paragraph>Enter details of the store closure.</Paragraph>
                <Form.Item name="duration" label="Duration of closure:">
                  <Radio.Group>
                    <Radio.Button value="all-day">All Day</Radio.Button>
                    <Radio.Button value="modified-hours">Modified Hours</Radio.Button>
                  </Radio.Group>
                </Form.Item>
                {getFieldValue('duration') === 'modified-hours' && (
                  <Row gutter={8} style={{ marginBottom: '24px' }}>
                  <Col span={12}>
                    <Form.Item name="openTime" label="Open Time" rules={[{ required: true, message: 'Please select the opening time!' }]}>
                    <TimePicker use12Hours format="h:mm a" minuteStep={15} /> 
                    </Form.Item>
                    </Col>
                    <Col span={12}>
                    <Form.Item name="closeTime" label="Close Time" rules={[{ required: true, message: 'Please select the closing time!' }]}>
                    <TimePicker use12Hours format="h:mm a" minuteStep={15} />

                    </Form.Item>
                  </Col>
                </Row>
                )}
            </Card>
            )} else if(getFieldValue('type') === 'technology-issue'){
              return (
              <Card title="Technology Issue" style={{ width: '50%' }} extra={<p>Details of any other technology issue impacting the store.</p>}>
                <Form.Item name="technologyIssue" label="Details" rules={[{ required: true, message: 'Please select the specific access issue!' }]}>
                    <Select placeholder="Select the specific access issue">
                      <Option value="1">The store is offline.</Option>
                      <Option value="2">The store is cell phone offline</Option>
                      <Option value="3">The computers or internet are offline.</Option>
                      <Option value="4">The gate or building keypads are offline.</Option>
                    </Select>
                  </Form.Item>
                  <Form.Item name="alternateContactNumber" label="Alternate Contact Number" style={{ width: '85%' }}>
                  <Input placeholder="Enter an alternative contact number. Company phones only." />
                  </Form.Item>

                  <Form.Item name="expectedResolutionTime" label="Expected Resolution Time">
                    <DatePicker style={{ width: '50%' }} />
                  </Form.Item>
            </Card>
            )} else if(getFieldValue('type') === 'custom'){
              return (
              <Card title="Custom Issue" style={{ width: '60%' }} extra={<p>Any other time-sensitive message the contact center needs to know about the store.</p>}>
                  <Form.Item  name="customMessage" label="Custom Message" style={{ width: '85%' }}>
                  <Input.TextArea placeholder="Enter a custom status update directly." />
                  </Form.Item>

            </Card>
            )};
          }}
        </Form.Item>
        <Title level={4}>Status Duration</Title>
        <Form.Item name="startDate" label="Start Date" rules={[{ required: true, message: 'Please select the start date!' }]}>
                  <DatePicker
            onChange={(date) => {
              if (autoExpiry && date) {
                form.setFieldsValue({ endDate: date.clone().startOf('day') });
              }
            }}
          />
          
        </Form.Item>

        <Form.Item name="autoExpiry" label="Auto Expiry" valuePropName="checked">
          <Switch checked={autoExpiry} onChange={onAutoExpiryChange} />
        </Form.Item>
        {autoExpiry && (
          <Form.Item name="endDate" label="End Date" rules={[{ required: true, message: 'Please select the end date!' }]}>
            <DatePicker />
          </Form.Item>
        )}
        <Row gutter={16}>
          <Col>
            <Button type="primary" htmlType="submit">
              {isEditMode ? 'Update' : 'Create'}
            </Button>
          </Col>
          <Col>
            <Button onClick={onBack}>Cancel</Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default StatusForm;



