import React, { useContext, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { MapContext } from './UnitMap';

const Unit = ({ id, color, onClick, onMouseOver, onMouseOut, onMouseDown, onMouseUp, onMouseMove }) => {
  const { map, units } = useContext(MapContext);

  const handleEvent = useCallback((event, handler) => {
    if (handler && id === event.unit.id) {
      handler(event.unit);
    }
  }, [id]);

  useEffect(() => {
    if (!map) {
      return;
    }

    const eventHandlers = {
      'unit:click': e => handleEvent(e, onClick),
      'unit:mouseover': e => handleEvent(e, onMouseOver),
      'unit:mouseout': e => handleEvent(e, onMouseOut),
      'unit:mousedown': e => handleEvent(e, onMouseDown),
      'unit:mouseup': e => handleEvent(e, onMouseUp),
      'unit:mousemove': e => {
        if (onMouseMove && id === e.unit.id) {
          onMouseMove(e.unit, e.originalEvent.clientX, e.originalEvent.clientY);
        }
      },
    };

    // Bind events
    Object.entries(eventHandlers).forEach(([eventName, handler]) => {
      map.on(eventName, handler);
    });

    // Set initial color
    units.find(id).color(color);

    // Cleanup function
    return () => {
      Object.entries(eventHandlers).forEach(([eventName, handler]) => {
        map.off(eventName, handler);
      });
      units.find(id).color("#fff");
    };
  }, [map, units, id, color, onClick, onMouseOver, onMouseOut, onMouseDown, onMouseUp, onMouseMove, handleEvent]);

  return null;
};

Unit.propTypes = {
  id: PropTypes.string.isRequired,
  color: PropTypes.string,
  onClick: PropTypes.func,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
  onMouseDown: PropTypes.func,
  onMouseUp: PropTypes.func,
  onMouseMove: PropTypes.func
};

Unit.defaultProps = {
  color: "#fff"
};

export default Unit;