import React, { useState, useEffect, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import { useNavigate } from 'react-router-dom';
import { fitBounds } from 'google-map-react';
import iStorage from '../assets/iStorage.png'
import nsa from '../assets/nsaLogoMark.png'
import moovein from '../assets/moovein.png'
import rightspace from '../assets/rightspace.jpeg'
import securcare from '../assets/securcare.png'
import northwest from '../assets/northwest.jpg'
import moveit from '../assets/moveit.png'
import store from "../redux/store"; 
import * as unitsSlice from '../features/units/unitsSlice'; 
import * as engrainSlice from '../features/engrain/engrainSlice';
import { updateSelectedFacilityDetails, fetchFacilityDetails } from '../features/facilities/facilitiesSlice';



const MapView = ({ facilities, hoveredRecord }) => {
  const [facilityDetailsLoading, setFacilityDetailsLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (window.google && facilities?.length > 0 && mapRef.current && mapsRef.current) {
      fitBounds(mapRef.current, mapsRef.current, facilities.map(fac => ({
        lat: parseFloat(fac.latitude),
        lng: parseFloat(fac.longitude)
      })));
    }
  }, [facilities]);

  const mapRef = useRef(null);
  const mapsRef = useRef(null);
  const cookie = document.cookie;
  const handleView = async (record) => {
    setFacilityDetailsLoading(true); 
    
    try {
      const facilityDetailsResponse = await store.dispatch(fetchFacilityDetails({ brand: record.brand, id: record.id, cookie: cookie })).unwrap();
      
      store.dispatch(updateSelectedFacilityDetails(facilityDetailsResponse));
      
      await store.dispatch(engrainSlice.fetchUnits());
      await store.dispatch(engrainSlice.fetchFloors());
      await store.dispatch(unitsSlice.fetchUnits(record.id));
  
      setFacilityDetailsLoading(false); 
      navigate(`/facilities/${record.id}`);
    } catch (error) {
      console.error('Error fetching facility details:', error);
      setFacilityDetailsLoading(false);
      // Handle error, e.g., navigate to error page or show error message
    }
  };
  const AnyReactComponent = ({ text, brand, isOne, record }) => {
 
    let logo;
    if (brand) {
      const brandLower = brand.toLowerCase();
     
      if(brandLower.includes('istorage')){
        logo = iStorage;
      }else if(brandLower.includes('securcare')){
        logo = securcare;
      }else if(brandLower.includes('northwest')){
        logo = northwest;
      }else if(brandLower.includes('move it')){
        logo = moveit;
      }else if(brandLower.includes('moove in')){
        logo = moovein;
      }else if(brandLower.includes('rightspace')){
        logo = rightspace;
      }else{
        logo = nsa;
      }
    } 
    const isHovered = hoveredRecord && hoveredRecord.id === record.id;
    const size = isHovered ? (isOne ? 60 : 40) : (isOne ? 30 : 20);
    const zIndex = isHovered ? 1000 : null;

    return (
      <img onClick={() => handleView(record)} src={logo} alt="Marker" 
        style={{
          width: `${size}px`, 
          height: `${size}px`,
          zIndex: `${zIndex}`,
          cursor: 'pointer',
          boxShadow: '0.5px 1.0px 1.0px hsl(0deg 0% 0% / 0.49)',
          position: 'absolute',
          transform: `translate(-${size / 2}px, -${size / 2}px)` // Centers the image
        }} 
      />
    )  };

  let center = {
    lat: 39.8283,
    lng: -98.5795
  };
  let zoom = 5;
  if(facilities?.length === 1){
    center = {
      lat: parseFloat(facilities[0].latitude),
      lng: parseFloat(facilities[0].longitude),
    }
    zoom = 10
  } 
  const fitBounds = (map, maps, locations) => {
    const bounds = new maps.LatLngBounds();
    
    // Extend the bounds to include each marker's position
    locations.forEach((location) => {
      bounds.extend(new maps.LatLng(location.lat || 0, location.lng || 0));
    });

    map.fitBounds(bounds);
  };
  const handleApiLoaded = ({ map, maps }) => {
    mapRef.current = map;
    mapsRef.current = maps;
    if (facilities?.length) {
      const locations = facilities.map(fac => ({
        lat: fac.latitude,
        lng: fac.longitude
      }));
      fitBounds(map, maps, locations);
    }
  };
  const createMapOptions = (maps) => {
    return {
      mapTypeId: facilities?.length === 1? maps.MapTypeId.SATELLITE: maps.MapTypeId.TERRAIN,
      mapTypeControl: true
    };
  };

  return (
    <div style={{ height: '500px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS }} // Replace with your Google Maps API key
        defaultCenter={center}
        defaultZoom={zoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={handleApiLoaded}
        options={createMapOptions}
      >
        {
          facilities && facilities.length > 0 ? (
            facilities.map((facility, index) => (
              <AnyReactComponent
                key={index}
                lat={facility.latitude}
                lng={facility.longitude}
                text={facility.name}
                brand={facility.brand_name}
                isOne={facilities.length === 1}
                record={facility}
              />
            ))
          ) : (
            // Optionally, render nothing or a placeholder component here if facilities are not available
            null // or <PlaceholderComponent />
          )
        }
      </GoogleMapReact>
    </div>
  );
};

export default MapView;