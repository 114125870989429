import React from 'react';
import { useSelector } from 'react-redux';
import nsa from '../assets/nsaLogoMark.png';
import './Spinner.css'; // Make sure to import your custom CSS

const Spinner = () => {
  const isLoading = useSelector((state) => state.loading.isLoading);

  if (!isLoading) return null;

  return (
    <div style={{
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100vh'
    }}>
        <img src={nsa} alt="Loading..." className="custom-spinner-image"/>
    </div>
  );
};

export default Spinner;
